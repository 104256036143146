import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "contexts/AppContext";

export const ActionCardsContext = createContext();

export const ActionCardsProvider = ({ children }) => {
  const { salesAgentCD, BASE_URL, oktaId, token } = useContext(AppContext);

  const [actionCards, setActionCards] = useState([]);

  // Fetch Action Cards
  const fetchActionCards = async () => {
    if (!salesAgentCD) return;
    try {
      const response = await axios.get(`${BASE_URL}/get-action-cards`, {
        params: { salesagentcd: salesAgentCD, oktaid: oktaId },
        headers: { Authorization: `Bearer ${token}` },
      });
      setActionCards(response.data);
      console.log("ACTION CARDS", salesAgentCD, response.data);
    } catch (err) {
      setActionCards([]);
      console.log("Problem with fetchActionCards", err);
    }
  };

  // Update Action Card Status
  const updateActionCardStatus = async (actionId, status, custcd) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/action-cards-status`,
        null, // No body needed
        {
          params: {
            action_id: actionId,
            status,
            oktaid: oktaId,
            salesagentcd: salesAgentCD,
            custcd: custcd,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(`Status updated for Action Card ${actionId}: ${status}`);

      // Refresh action cards only if status is not null or reviewed
      if (status !== null && status !== "reviewed") {
        fetchActionCards(); // Refresh action cards after update
      }
      
      return response.data;
    } catch (err) {
      console.log(
        `Problem with updateActionCardStatus for Action ID ${actionId}:`,
        err
      );
      throw err;
    }
  };

  // Submit Feedback
  const submitFeedback = async (actionId, rating) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/action-card-feedback`,
        null, // No body needed
        {
          params: { action_id: actionId, rating, oktaid: oktaId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(
        `Feedback submitted for Action Card ${actionId}: Rating - ${rating}`
      );
      return response.data;
    } catch (err) {
      console.log(
        `Problem with submitFeedback for Action ID ${actionId}:`,
        err
      );
      throw err;
    }
  };

  // Submit Follow-up
  const submitFollowup = async (actionId, followupContent, custcd) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/submit-followup`,
        null, // No body needed
        {
          params: {
            action_id: actionId,
            followup_content: followupContent,
            oktaid: oktaId,
            salesagentcd: salesAgentCD,
            custcd: custcd,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(`Follow-up submitted for Action Card ${actionId}`);
      fetchActionCards(); // Refresh action cards after submitting follow-up
      return response.data;
    } catch (err) {
      console.log(
        `Problem with submitFollowup for Action ID ${actionId}:`,
        err
      );
      throw err;
    }
  };

  useEffect(() => {
    fetchActionCards();
  }, [salesAgentCD]);

  return (
    <ActionCardsContext.Provider
      value={{
        actionCards,
        updateActionCardStatus,
        submitFeedback,
        submitFollowup,
      }}
    >
      {children}
    </ActionCardsContext.Provider>
  );
};
