import * as React from "react"
import { useState } from "react"
import { Mail, Phone, Star, MapPin, Globe, Sparkles, Target, ExternalLink, Info } from 'lucide-react'

import { Button } from "../../../components/ui/button"
import {
  Card,
  CardContent,
  CardHeader,
  CardFooter,
  CardTitle,
} from "../../../components/ui/card"
import { Badge } from "../../../components/ui/badge"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../components/ui/tooltip"
import { EmailPopup } from "./email-popup"

const certificationColors = {
  "President's Club Award": "bg-purple-100 text-purple-800 border-purple-200",
  "President's Award": "bg-purple-100 text-purple-800 border-purple-200",
  "Master Elite®": "bg-blue-100 text-blue-800 border-blue-200",
  "Master Elite": "bg-blue-100 text-blue-800 border-blue-200",
  "Certified Installer": "bg-fuchsia-100 text-fuchsia-800 border-fuchsia-200",
  "Green Roofing Specialist": "bg-emerald-100 text-emerald-800 border-emerald-200",
  "Safety Certified": "bg-red-100 text-red-800 border-red-200",
  "Innovative Design Award": "bg-indigo-100 text-indigo-800 border-indigo-200",
  "Energy Efficiency Expert": "bg-yellow-100 text-yellow-800 border-yellow-200",
  "Community Choice Award": "bg-pink-100 text-pink-800 border-pink-200",
  "Reliability Certified": "bg-cyan-100 text-cyan-800 border-cyan-200",
  "Sustainability Award": "bg-teal-100 text-teal-800 border-teal-200",
  "Emergency Response Certified": "bg-orange-100 text-orange-800 border-orange-200",
  "Customer Service Excellence": "bg-rose-100 text-rose-800 border-rose-200",
  "Historical Preservation Expert": "bg-amber-100 text-amber-800 border-amber-200",
  "Craftsmanship Award": "bg-lime-100 text-lime-800 border-lime-200",
  "Solar Roofing Specialist": "bg-violet-100 text-violet-800 border-violet-200",
  "Energy Star Partner": "bg-sky-100 text-sky-800 border-sky-200",
  "Commercial Expert": "bg-blue-100 text-blue-800 border-blue-200"
}

export function ContractorCard({
  name,
  description,
  certifications = [],
  address,
  rating,
  reviewCount,
  phone,
  website,
  websitePreview,
  analysis,
  onClick,
  showActions = true,
  status = "Prospective Opportunity",
  email
}) {
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false)

  const statusColors = {
    "Prospective Opportunity": "bg-blue-50 text-blue-700 border-blue-400",
    "Contacted": "bg-amber-50 text-amber-700 border-amber-400",
    "Confirmed Lead": "bg-emerald-50 text-emerald-700 border-emerald-400",
    "Converted Customer": "bg-violet-50 text-violet-700 border-violet-400"
  }

  const handleEmailClick = (e) => {
    e.stopPropagation()
    setIsEmailPopupOpen(true)
  }

  // Ensure certifications is an array and handle potential string values
  const certificationList = Array.isArray(certifications) 
    ? certifications 
    : typeof certifications === 'string'
      ? JSON.parse(certifications.replace(/'/g, '"'))
      : [];

  // Helper function to check if website/link is valid
  const isValidLink = (link) => link && link !== "No website" && link !== "";

  return (
    <Card 
      className="w-full hover:shadow-lg transition-shadow duration-300 cursor-pointer h-[450px] flex flex-col"
      onClick={onClick}
    >
      <CardHeader className="pb-3 flex-none">
        <div className="flex justify-between items-start">
          <div>
            <CardTitle className="text-lg font-bold text-gray-800 line-clamp-1">{name}</CardTitle>
          </div>
          <div className="flex items-center gap-1 bg-blue-50 px-2 py-1 rounded-full flex-none">
            <Star className="w-3.5 h-3.5 fill-yellow-400 text-yellow-400" />
            <span className="text-sm font-medium text-blue-600">{rating}</span>
            <span className="text-xs text-gray-500">({reviewCount})</span>
          </div>
        </div>
      </CardHeader>
      <CardContent className="pb-0 space-y-3 flex-1 overflow-hidden">
        <div className="flex flex-wrap gap-1 min-h-[32px] items-start">
          {certificationList.map((cert) => (
            <Badge 
              key={cert} 
              variant="secondary" 
              className={`text-[10px] leading-none px-1.5 py-[2px] font-medium whitespace-nowrap h-[18px] flex items-center ${certificationColors[cert] || 'bg-gray-100 text-gray-800'}`}
            >
              {cert}
            </Badge>
          ))}
        </div>
        <div className="text-sm text-gray-600 flex items-center gap-2">
          <Target className="w-4 h-4 mr-0.5 flex-shrink-0 text-gray-600" />
          <span className="font-semibold">Status:</span>
          <Badge 
            variant="secondary"
            className={`text-xs leading-none px-2 py-1 font-medium whitespace-nowrap h-[22px] flex items-center border-2 ${statusColors[status]}`}
          >
            {status}
          </Badge>
        </div>
        <div className="text-sm text-gray-600 flex items-center">
          <MapPin className="w-4 h-4 mr-1.5 flex-shrink-0" />
          <span className="line-clamp-1">{address}</span>
        </div>
        <div className="text-sm text-gray-600 flex items-center">
          <Globe className="w-4 h-4 mr-1.5 flex-shrink-0" />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <span 
                  className={`${
                    isValidLink(website) 
                      ? 'text-blue-600 hover:underline cursor-pointer' 
                      : 'text-gray-400 cursor-default'
                  } line-clamp-1`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isValidLink(website)) {
                      window.open(website, '_blank', 'noopener,noreferrer');
                    }
                  }}
                >
                  Visit Website
                </span>
              </TooltipTrigger>
              {!isValidLink(website) && (
                <TooltipContent>
                  <p>No website available</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="text-sm text-gray-600 flex items-center">
          <ExternalLink className="w-4 h-4 mr-1.5 flex-shrink-0" />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <span 
                  className={`${
                    isValidLink(websitePreview) 
                      ? 'text-blue-600 hover:underline cursor-pointer' 
                      : 'text-gray-400 cursor-default'
                  } line-clamp-1`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isValidLink(websitePreview)) {
                      window.open(websitePreview, '_blank', 'noopener,noreferrer');
                    }
                  }}
                >
                  Visit Listing
                </span>
              </TooltipTrigger>
              {!isValidLink(websitePreview) && (
                <TooltipContent>
                  <p>No listing available</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="bg-white p-2.5 rounded-md border border-gray-200 shadow-sm">
          <div className="flex items-center mb-1">
            <Sparkles className="w-4 h-4 mr-1.5 text-blue-600" />
            <h4 className="text-base font-semibold text-gray-800">AI Insight</h4>
          </div>
          <p className="text-sm leading-relaxed text-gray-700 line-clamp-4 font-medium overflow-y-scroll">{analysis}</p>
        </div>
      </CardContent>
      {showActions && (
        <CardFooter className="grid grid-cols-2 gap-3 pt-0 mt-2 flex-none">
          <Button 
            size="sm"
            className="w-full bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-300 flex items-center justify-center"
            onClick={(e) => {
              e.stopPropagation()
              window.location.href = `tel:${phone}`
            }}
          >
            <Phone className="w-3.5 h-3.5 mr-1.5" />
            Call
          </Button>
          <Button 
            size="sm"
            variant="outline"
            className="w-full border-blue-200 text-blue-600 hover:bg-blue-50 transition-colors duration-300 flex items-center justify-center"
            onClick={handleEmailClick}
          >
            <Mail className="w-3.5 h-3.5 mr-1.5" />
            Email
          </Button>
        </CardFooter>
      )}
      <EmailPopup
        isOpen={isEmailPopupOpen}
        onClose={() => setIsEmailPopupOpen(false)}
        contractorName={name}
        contractorEmail={email || `${name.replace(/\s+/g, '')}@example.com`}
      />
    </Card>
  )
}