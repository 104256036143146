import { Text } from 'components/catalyst/text'
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card'
import { useState, useEffect } from 'react'

export default function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap w-full pt-4 justify-center my-8">
        <Card className="w-[600px]">
          <CardHeader>
            <Text><strong>Privacy Policy</strong></Text>
          </CardHeader>
          <CardContent>
            x
          </CardContent>
          <CardFooter>
            x
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}