'use client'

import { useState, useCallback, useMemo, useContext, useEffect } from 'react'
import { Button } from "components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { Switch } from "components/ui/switch"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table"
import { Upload, File, Lock, Globe, Trash2 } from "lucide-react"
import { toast } from 'sonner'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "components/ui/pagination"
import { AppContext } from 'contexts/AppContext'

const categories = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5']

/* file_name: 'example.txt',
file_type: 'txt',
scope: 'public',
file_size: 1024,
tags: ['tag1', 'tag2'], */

export default function FileUploadPage() {
  const { nucliaUpload, nucliaFiles, deleteFile } = useContext(AppContext);

  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateSort, setDateSort] = useState('newest');
  const [visibilityFilter, setVisibilityFilter] = useState('all');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [fileToDelete, setFileToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const newFiles = Array.from(e.dataTransfer.files).map((file) => ({
        file,
        category: categories[0],
        isPublic: false,
      }));
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files).map((file) => ({
        file,
        category: categories[0],
        isPublic: false,
      }));
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleCategoryChange = (fileIndex, category) => {
    setFiles((prevFiles) =>
      prevFiles.map((file, index) => (index === fileIndex ? { ...file, category } : file))
    );
  };

  const handlePublicChange = (fileIndex, isPublic) => {
    setFiles((prevFiles) =>
      prevFiles.map((file, index) => (index === fileIndex ? { ...file, isPublic } : file))
    );
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      toast.error('Please select at least one file to upload.');
      return;
    }

    setIsUploading(true);

    try {
      // Transform file_content into Base64 strings
      const formattedAttachments = await Promise.all(
        files.map((file) => {
          const reader = new FileReader();
          return new Promise((resolve) => {
            reader.onload = (e) => {
              const binaryString = e.target.result; // Read file as binary string
              const base64String = btoa(binaryString); // Convert to Base64
  
              resolve({
                file_content: base64String, // Base64-encoded content
                file_name: file.file.name,
                file_type: file.file.type,
                file_size: file.file.size,
                tags: [], // Add any tags as needed
                scope: file.isPublic ? "public" : "private",
                category: file.category,
              });
            };
            reader.readAsBinaryString(file.file);
          });
        })
      );
  
      // Upload all formatted attachments
      const uploadPromises = formattedAttachments.map((payload) => nucliaUpload(payload));
      await Promise.all(uploadPromises);
  
      setFiles([]); // Clear selected files
      toast.success(`${files.length} file(s) uploaded successfully.`);
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("An error occurred during the upload. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDelete = useCallback((file) => {
    setFileToDelete(file);
  }, []);

  const confirmDelete = useCallback(async () => {
    if (fileToDelete) {
      try {
        await deleteFile(fileToDelete.file_id);
        toast.success(`File "${fileToDelete.name}" has been deleted.`);
        setFileToDelete(null);
      } catch (error) {
        console.error('Error deleting file:', error);
        toast.error('Failed to delete the file. Please try again.');
      }
    }
  }, [fileToDelete, deleteFile]);

  const filteredAndSortedHistory = useMemo(() => {
    if (!Array.isArray(nucliaFiles) || nucliaFiles.length === 0) return [];
    return nucliaFiles
      .filter((file) => {
        const nameMatch = file.file_name.toLowerCase().includes(searchTerm.toLowerCase());
        const visibilityMatch =
          visibilityFilter === 'all' ||
          (visibilityFilter === 'public' && file.scope === 'public') ||
          (visibilityFilter === 'private' && file.scope === 'private');
        const categoryMatch = categoryFilter === 'all' || file.category === categoryFilter;

        return nameMatch && visibilityMatch && categoryMatch;
      })
      .sort((a, b) => {
        if (dateSort === 'newest') {
          return new Date(b.timestamp) - new Date(a.timestamp);
        } else {
          return new Date(a.timestamp) - new Date(b.timestamp);
        }
      });
  }, [nucliaFiles, searchTerm, dateSort, visibilityFilter, categoryFilter]);

  const paginatedHistory = useMemo(() => {
    if (!filteredAndSortedHistory || filteredAndSortedHistory.length === 0) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredAndSortedHistory.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredAndSortedHistory, currentPage]);

  const totalPages = Math.ceil(filteredAndSortedHistory.length / itemsPerPage);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container max-w-4xl mx-auto px-4 py-12">
      {/* Upload Section */}
      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Upload File(s)</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleUpload}>
            <div
              className={`relative border-2 border-dashed rounded-lg p-8 text-center transition-colors ${
                dragActive ? 'border-primary bg-gray-100/80' : 'border-gray-300'
              }`}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <Input id="file-upload" type="file" className="hidden" multiple onChange={handleChange} />
              <Label htmlFor="file-upload" className="cursor-pointer block h-full w-full">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <p className="mt-2 text-sm text-gray-500">
                  Drag and drop files here, or click to select files
                </p>
              </Label>
            </div>
            {files.length > 0 && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">Selected Files:</h3>
                <ul className="space-y-2">
                  {files.map((file, index) => (
                    <li key={index} className="flex items-center justify-between">
                      <span>{file.file.name}</span>
                      <div className="flex items-center space-x-4">
                        <Select
                          value={file.category}
                          onValueChange={(category) => handleCategoryChange(index, category)}
                        >
                          <SelectTrigger className="w-[140px]">
                            <SelectValue placeholder="Select a category" />
                          </SelectTrigger>
                          <SelectContent>
                            {categories.map((category) => (
                              <SelectItem key={category} value={category}>
                                {category}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <div className="flex items-center space-x-2">
                          <Switch
                            id={`public-switch-${index}`}
                            checked={file.isPublic}
                            onCheckedChange={(isPublic) => handlePublicChange(index, isPublic)}
                          />
                          <Label htmlFor={`public-switch-${index}`}>Make Public</Label>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="mt-4">
              <Button type="submit" disabled={isUploading} className="w-full">
                {isUploading ? 'Uploading...' : 'Upload'}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>

      {/* Upload History Section */}
      <Card>
        <CardHeader>
          <CardTitle>Upload History</CardTitle>
        </CardHeader>
        <CardContent>
          {/* Filters */}
          <div className="flex items-center space-x-2 mb-4">
            <Input
              placeholder="Search by file name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-grow"
            />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">Date</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => setDateSort('newest')}>Newest First</DropdownMenuItem>
                <DropdownMenuItem onClick={() => setDateSort('oldest')}>Oldest First</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">Visibility</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => setVisibilityFilter('all')}>All</DropdownMenuItem>
                <DropdownMenuItem onClick={() => setVisibilityFilter('public')}>Public</DropdownMenuItem>
                <DropdownMenuItem onClick={() => setVisibilityFilter('private')}>Private</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">Category</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => setCategoryFilter('all')}>All</DropdownMenuItem>
                {categories.map((category) => (
                  <DropdownMenuItem key={category} onClick={() => setCategoryFilter(category)}>
                    {category}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {/* Table */}
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>File Name</TableHead>
                <TableHead>Size</TableHead>
                <TableHead>Upload Date</TableHead>
                <TableHead>Visibility</TableHead>
                <TableHead>Category</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
            {filteredAndSortedHistory.length === 0 ? (
              <div className="flex items-center justify-center text-center text-gray-500 py-4">
                No files have been uploaded.
              </div>
            ) : (
              <>
                {paginatedHistory.map((file) => (
                  <TableRow key={file.file_id}>
                    <TableCell className="font-medium">
                      <div className="flex items-center">
                        <File className="h-4 w-4 mr-2" />
                        {file.file_name}
                      </div>
                    </TableCell>
                    <TableCell>{`${(file.file_size / 1024 / 1024).toFixed(2)} MB`}</TableCell>
                    <TableCell>{new Date(file.timestamp).toLocaleDateString()}</TableCell>
                    <TableCell>
                      {file.scope === 'public' ? (
                        <Globe className="h-4 w-4 text-green-500" />
                      ) : (
                        <Lock className="h-4 w-4 text-gray-500" />
                      )}
                    </TableCell>
                    <TableCell>{file.category}</TableCell>
                    <TableCell>
                      <Button variant="ghost" size="sm" onClick={() => handleDelete(file)}>
                        <Trash2 className="h-4 w-4 text-red-500" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </>
              )}
            </TableBody>
          </Table>

          {/* Pagination */}
          <div className="mt-4">
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                  />
                </PaginationItem>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                  <PaginationItem key={page}>
                    <PaginationLink
                      onClick={() => setCurrentPage(page)}
                      isActive={currentPage === page}
                    >
                      {page}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem>
                  <PaginationNext
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        </CardContent>
      </Card>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={!!fileToDelete} onOpenChange={() => setFileToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to delete this file?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the file "{fileToDelete?.file_name}".
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDelete}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
