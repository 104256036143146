import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const customerData = {
  name: 'Company1',
  email: 'john.doe@example.com',
  phone: '+1 (555) 123-4567',
  totalPurchases: 12,
  totalSpent: 2500,
  lastPurchase: '2023-06-10',
};

const touchpointData = [
  { name: 'Email', value: 40 },
  { name: 'Phone', value: 30 },
  { name: 'Website', value: 20 },
  { name: 'Social', value: 10 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const CustomerProfile = () => {
  return (
    <div className="grid gap-4 md:grid-cols-2">
      <Card>
        <CardHeader>
          <CardTitle>Customer Information</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <p><strong>Company:</strong> {customerData.name}</p>
            <p><strong>Email:</strong> {customerData.email}</p>
            <p><strong>Phone:</strong> {customerData.phone}</p>
            <p><strong>Total Purchases:</strong> {customerData.totalPurchases}</p>
            <p><strong>Total Spent:</strong> ${customerData.totalSpent}</p>
            <p><strong>Last Purchase:</strong> {customerData.lastPurchase}</p>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Touchpoint Distribution</CardTitle>
        </CardHeader>
        <CardContent className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={touchpointData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {touchpointData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default CustomerProfile;

