import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "contexts/AppContext";

export const LeadGenContext = createContext();

export const LeadGenProvider = ({ children }) => {
    const { salesAgentCD, BASE_URL, oktaId, token } = useContext(AppContext);
    const [leads, setLeads] = useState([])

    const fetchLeads = async () => {
        if (!salesAgentCD) return;
        try {
          const response = await axios.get(`${BASE_URL}/get-leads`, {
            params: { salesagentcd: salesAgentCD, oktaid: oktaId },
            headers: { Authorization: `Bearer ${token}` },
          });
          setLeads(response.data);
          console.log("Leads", salesAgentCD, response.data);
        } catch (err) {
          setLeads([]);
          console.log("Problem with fetchLeads", err);
        }
      };

      useEffect(() => {
        fetchLeads();
      }, [salesAgentCD]);

    return (
    <LeadGenContext.Provider
      value={{
        leads, fetchLeads
      }}
    >
      {children}
    </LeadGenContext.Provider>
  );
};

