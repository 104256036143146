import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import { Card, CardContent } from "components/ui/card"
import { Input } from "components/ui/input"
import { Search } from 'lucide-react'
import { ScrollArea, ScrollBar } from "components/ui/scroll-area"
import Dashboard from './components/Dashboard';
import CustomerJourneyMap from './components/CustomerJourneyMap';
import RecentTouchpoints from './components/RecentTouchpoints';
import AddTouchpoint from './components/AddTouchpoint';
import CustomerProfile from './components/CustomerProfile';
import TouchpointsList from './components/TouchpointsList';

const TouchpointSystem = ({ custcd }) => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className="w-full">
      <div className="flex flex-col gap-6">
        <Card className="w-full">
          <CardContent className="p-0">
            <Tabs defaultValue="new" className="w-full">
              <div className="border-b">
                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                  <ScrollArea className="w-full md:w-auto" orientation="horizontal">
                    <div className="flex w-max p-2">
                      <TabsList className="h-auto inline-flex bg-muted rounded-full p-1 gap-1">
                        {/* <TabsTrigger 
                          value="journey" 
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Journey
                        </TabsTrigger> */}
                        <TabsTrigger 
                          value="new" 
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Add Touchpoint
                        </TabsTrigger>
                        <TabsTrigger 
                          value="touchpoints" 
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Touchpoints
                        </TabsTrigger>
                        {/* <TabsTrigger 
                          value="analytics" 
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Analytics
                        </TabsTrigger>
                        <TabsTrigger 
                          value="interactions" 
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Interactions
                        </TabsTrigger> */}
                        <TabsTrigger 
                          value="profile" 
                          className="rounded-full px-4 py-2 text-sm font-medium transition-all data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
                        >
                          Profile
                        </TabsTrigger>
                      </TabsList>
                    </div>
                    <ScrollBar orientation="horizontal" className="h-2.5 bg-muted" />
                  </ScrollArea>
                  {/* <div className="relative w-full md:w-96 p-2">
                    <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                    <Input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="pl-10 py-2 text-sm w-full"
                    />
                  </div> */}
                </div>
              </div>
              <div className="p-2 sm:p-4">
                <TabsContent value="journey" className="m-0">
                  <CustomerJourneyMap />
                </TabsContent>
                <TabsContent value="touchpoints" className="m-0">
                  <TouchpointsList custcd={custcd} />
                </TabsContent>
                <TabsContent value="analytics" className="m-0">
                  <Dashboard />
                </TabsContent>
                <TabsContent value="interactions" className="m-0">
                  <RecentTouchpoints searchTerm={searchTerm} />
                </TabsContent>
                <TabsContent value="new" className="m-0">
                  <AddTouchpoint />
                </TabsContent>
                <TabsContent value="profile" className="m-0">
                  <CustomerProfile />
                </TabsContent>
              </div>
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default TouchpointSystem;

