import OpenAI from 'openai'; 

const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true })

export const whisperAPI = async (file) => {
    console.log("Transcribing audio with whisper API...")
    try {
      console.log("Sending audio to OpenAI...")
      const transcription = await openai.audio.transcriptions.create({
        file: file,
        model: "whisper-1",
        response_format: "text",
      });
  
      console.log(`transcription: `, transcription);
      return transcription;
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
}
  
export const formatTranscript = async (transcript) => {
    const context = `
      Your fist purpose is to remove any stuttering or filler words from the transcript below and format it as user desires in the description as FORMATTED DESCRIPTION. 
      For example, if they require bullet points, format the FORMATTED DESCRIPTION with markdown style into bullet points. Or for example they may ask you to format into succinct paragraphs.
      The transcript is a response from the Whisper API. 
  
      Your second purpose is to create a TITLE that summarizes the transcript.
      Your third purpose is to determine if the transcript is a reminder.
      If it is a reminder, return the reminder in the follwing JSON format:
      "{header: TITLE, description: FORMATTED DESCRIPTION, reminder: true}".
      If it is not a reminder, return the transcript in the following JSON format:
      "{header: TITLE, description: FORMATTED DESCRIPTION, reminder: false}".
      
      Only reutrn JSON format and nothing else. 
  
      TRANSCRIPT: ` + transcript + `; 
    `
    let messages = [{ role: "system", content: context }];
  
    try {
      const completion = await openai.chat.completions.create({
          model: "gpt-4o", 
          messages: messages,
          response_format: {"type": "json_object"},
      });
      console.log('completion', completion)
      return completion.choices[0].message.content
    } catch (error) {
        console.error("Error summarizing and formatting transcript:", error);
    }
}