import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardDescription, CardFooter, CardHeader } from 'components/ui/card'
import { ContractorCard } from '../../pages/lead-generation/components/contractor-card'
import { ContractorModal } from '../../pages/lead-generation/components/contractor-modal'
import { LeadGenContext } from 'contexts/LeadGenContext'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/ui/carousel"

// Helper function to safely parse numeric values
const safeParseFloat = (value, defaultValue = 0) => {
  if (!value) return defaultValue;
  const parsed = parseFloat(value);
  return isNaN(parsed) ? defaultValue : parsed;
};

// Helper function to parse certifications
const parseCertifications = (certStr) => {
  if (!certStr) return [];
  
  try {
    const cleanStr = certStr.replace(/^["']|["']$/g, '');
    const withoutBrackets = cleanStr.slice(1, -1);
    
    return withoutBrackets
      .split(',')
      .map(item => 
        item
          .trim()
          .replace(/^['"]|['"]$/g, '')
      )
      .filter(Boolean);
      
  } catch (error) {
    console.error('Error parsing certifications:', error);
    return [];
  }
};

// Helper function to transform lead data
const transformLeadData = (lead, index) => {
  // Safely parse numeric values
  const rating = safeParseFloat(lead.rating, 0).toFixed(1);
  const reviews = safeParseFloat(lead.reviews, 0);
  const score = safeParseFloat(lead.score, 0);

  return {
    id: index.toString(),
    post_link: lead.post_link || "",
    name: lead.name || "",
    description: lead.description || "",
    certifications: parseCertifications(lead.certifications),
    address: lead.address || "",
    analysis: lead.ai_insights || "",
    rating: rating,
    reviewCount: Math.round(reviews),
    phone: lead.phone || "",
    websitePreview: lead.website || "",
    source: lead.source || "",
    status: "Prospective Opportunity",
    email: lead.email || "",
    score: score
  };
};

export function LeadGeneration() {
  const { leads } = useContext(LeadGenContext);
  const [selectedContractor, setSelectedContractor] = useState(null);

  // Transform all leads with null checks
  const transformedLeads = (leads || []).map(transformLeadData);

  return (
    <div className="sm:mt-4">
      <Card className="h-full sm:mt-0">
        <CardHeader>
          <h2 className="text-lg font-semibold">Recent Leads</h2>
          <CardDescription>Latest potential customers and opportunities</CardDescription>
        </CardHeader>
        <div className="px-6 pb-6">
          <Carousel
            opts={{
              align: "start",
              loop: true,
            }}
            className="w-full"
          >
            <CarouselContent className="-ml-2 md:-ml-4">
              {transformedLeads.map((lead) => (
                <CarouselItem key={lead.id} className="pl-2 md:pl-4 basis-full md:basis-1/2 lg:basis-1/3">
                  <ContractorCard
                    {...lead}
                    rating={lead.rating}
                    reviewCount={lead.reviewCount}
                    onClick={() => setSelectedContractor(lead.id)}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
          <div className="mt-4 text-center">
            <Link 
              to="/lead-generation"
              className="text-sm text-blue-600 hover:text-blue-800 hover:underline"
            >
              View All Leads →
            </Link>
          </div>
        </div>
        <CardFooter></CardFooter>
      </Card>

      <ContractorModal 
        isOpen={!!selectedContractor}
        onClose={() => setSelectedContractor(null)}
        contractorId={selectedContractor}
        contractors={transformedLeads}
      />
    </div>
  );
}