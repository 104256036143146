'use client'

import * as React from "react"
import { useState } from "react"
import { Dialog, DialogContent } from "../../../components/ui/dialog"
import { Badge } from "../../../components/ui/badge"
import { Button } from "../../../components/ui/button"
import { Mail, Phone, Star, MapPin, Sparkles, Globe, Target, ExternalLink } from 'lucide-react'
import { EmailPopup } from "./email-popup"

const certificationColors = {
  "President's Club Award": "bg-purple-100 text-purple-800 border-purple-200",
  "President's Award": "bg-purple-100 text-purple-800 border-purple-200",
  "Master Elite®": "bg-blue-100 text-blue-800 border-blue-200",
  "Master Elite": "bg-blue-100 text-blue-800 border-blue-200",
  "Certified Installer": "bg-fuchsia-100 text-fuchsia-800 border-fuchsia-200",
  "Green Roofing Specialist": "bg-emerald-100 text-emerald-800 border-emerald-200",
  "Safety Certified": "bg-red-100 text-red-800 border-red-200",
  "Innovative Design Award": "bg-indigo-100 text-indigo-800 border-indigo-200",
  "Energy Efficiency Expert": "bg-yellow-100 text-yellow-800 border-yellow-200",
  "Community Choice Award": "bg-pink-100 text-pink-800 border-pink-200",
  "Reliability Certified": "bg-cyan-100 text-cyan-800 border-cyan-200",
  "Sustainability Award": "bg-teal-100 text-teal-800 border-teal-200",
  "Emergency Response Certified": "bg-orange-100 text-orange-800 border-orange-200",
  "Customer Service Excellence": "bg-rose-100 text-rose-800 border-rose-200",
  "Historical Preservation Expert": "bg-amber-100 text-amber-800 border-amber-200",
  "Craftsmanship Award": "bg-lime-100 text-lime-800 border-lime-200",
  "Solar Roofing Specialist": "bg-violet-100 text-violet-800 border-violet-200",
  "Energy Star Partner": "bg-sky-100 text-sky-800 border-sky-200",
  "Commercial Expert": "bg-blue-100 text-blue-800 border-blue-200"
}

const statusColors = {
  "Prospective Opportunity": "bg-blue-50 text-blue-700 border-blue-400",
  "Contacted": "bg-amber-50 text-amber-700 border-amber-400",
  "Confirmed Lead": "bg-emerald-50 text-emerald-700 border-emerald-400",
  "Converted Customer": "bg-violet-50 text-violet-700 border-violet-400"
}

// Helper function to safely parse numeric values
const safeParseFloat = (value, defaultValue = 0) => {
  if (value === null || value === undefined || value === '') return defaultValue;
  const parsed = parseFloat(value);
  return isNaN(parsed) ? defaultValue : parsed;
};

export function ContractorModal({ isOpen, onClose, contractorId, contractors }) {
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false)
  
  // Find the contractor using numeric ID
  const contractor = contractors.find(c => c.id === contractorId)
  
  if (!contractor) return null

  // Safely parse numeric values
  const rating = safeParseFloat(contractor.rating, 0).toFixed(1);
  const reviewCount = Math.round(safeParseFloat(contractor.reviewCount, 0));

  const handleEmailClick = (e) => {
    e.stopPropagation()
    setIsEmailPopupOpen(true)
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="space-y-6">
          {/* Header */}
          <div>
            <div className="flex justify-between items-start mb-4">
              <div>
                <h2 className="text-xl font-bold text-gray-800">{contractor.name}</h2>
                <p className="text-sm text-gray-600 mt-1">{contractor.description || ''}</p>
              </div>
              <div className="flex items-center gap-1 bg-blue-50 px-3 py-1.5 rounded-full">
                <Star className="w-4 h-4 fill-yellow-400 text-yellow-400" />
                <span className="text-sm font-medium text-blue-600">{rating}</span>
                <span className="text-xs text-gray-500">({reviewCount.toLocaleString()})</span>
              </div>
            </div>

            {/* Certifications */}
            <div className="flex flex-wrap gap-2 mb-4">
              {(contractor.certifications || []).map((cert) => (
                <Badge 
                  key={cert} 
                  variant="secondary" 
                  className={`text-xs px-2 py-0.5 ${certificationColors[cert] || 'bg-gray-100 text-gray-800'}`}
                >
                  {cert}
                </Badge>
              ))}
            </div>

            {/* Status */}
            <div className="text-sm text-gray-600 flex items-center gap-2 mb-4">
              <Target className="w-4 h-4 mr-0.5 flex-shrink-0 text-gray-600" />
              <span className="font-semibold">Status:</span>
              <Badge 
                variant="secondary"
                className={`text-sm leading-none px-2.5 py-1 font-medium whitespace-nowrap h-[24px] flex items-center border-2 ${statusColors[contractor.status] || ''}`}
              >
                {contractor.status || 'Prospective Opportunity'}
              </Badge>
            </div>

            {/* Address */}
            <div className="text-sm text-gray-600 flex items-center mb-4">
              <MapPin className="w-4 h-4 mr-2 flex-shrink-0" />
              <span>{contractor.address || ''}</span>
            </div>

            {/* Website */}
            <div className="text-sm text-gray-600 flex items-center mb-4">
              <Globe className="w-4 h-4 mr-2 flex-shrink-0" />
              <a 
                href={contractor.websitePreview} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-600 hover:underline"
                onClick={(e) => e.stopPropagation()}
              >
                Visit Website
              </a>
            </div>

            {/* Listing */}
            <div className="text-sm text-gray-600 flex items-center mb-4">
              <ExternalLink className="w-4 h-4 mr-2 flex-shrink-0" />
              <a 
                href={contractor.post_link} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-600 hover:underline"
                onClick={(e) => e.stopPropagation()}
              >
                Visit Listing
              </a>
            </div>
          </div>

          {/* AI Insight */}
          <div className="bg-white p-3 rounded-md border border-gray-200 shadow-sm">
            <div className="flex items-center mb-2">
              <Sparkles className="w-5 h-5 mr-2 text-blue-600" />
              <h4 className="text-lg font-semibold text-gray-800">AI Insight</h4>
            </div>
            <p className="text-base leading-relaxed text-gray-700 font-medium whitespace-pre-wrap">
              {contractor.analysis?.trim() || 'No AI insights available'}
            </p>
          </div>

          {/* Action Buttons */}
          <div className="grid grid-cols-2 gap-4">
            <Button 
              size="default" 
              className="w-full bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-300 flex items-center justify-center"
              onClick={() => window.location.href = `tel:${contractor.phone}`}
            >
              <Phone className="w-4 h-4 mr-2" />
              Call
            </Button>
            <Button 
              size="default" 
              variant="outline"
              className="w-full border-blue-200 text-blue-600 hover:bg-blue-50 transition-colors duration-300 flex items-center justify-center"
              onClick={handleEmailClick}
            >
              <Mail className="w-4 h-4 mr-2" />
              Email
            </Button>
          </div>
        </div>
      </DialogContent>
      <EmailPopup
        isOpen={isEmailPopupOpen}
        onClose={() => setIsEmailPopupOpen(false)}
        contractorName={contractor.name || ''}
        contractorEmail={contractor.email || `${contractor.name?.replace(/\s+/g, '')}@example.com`}
      />
    </Dialog>
  )
}