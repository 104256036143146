import { Card, CardHeader, CardFooter } from '../../components/ui/card'
import { Notes } from '../../components/notes-component/notes';
import { useState, useEffect } from 'react'

export default function NotesPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap pt-4">
        <Card className="w-full">
            <CardHeader>
              <Notes navCollapsedSize={50} />
            </CardHeader>
            <CardFooter></CardFooter>
        </Card>
      </div>
    </div>
  )
}