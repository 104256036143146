import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table"
import { Badge } from "components/ui/badge"

const touchpoints = [
  { id: 1, customer: 'Customer1', type: 'Email', content: 'Product inquiry', date: '2023-06-15T10:30:00', direction: 'Incoming', summary: 'Customer asked about product availability', sender: 'customer1@example.com', recipients: ['sales@company.com'], subject: 'Product Inquiry' },
  { id: 2, customer: 'Customer2', type: 'Phone', content: 'Order status check', date: '2023-06-14T14:45:00', direction: 'Outgoing', summary: 'Informed customer about order shipment', sender: 'sales@company.com', recipients: ['customer2@example.com'], subject: 'Order Status Update' },
  { id: 3, customer: 'Customer3', type: 'SMS', content: 'Appointment confirmation', date: '2023-06-13T09:15:00', direction: 'Outgoing', summary: 'Confirmed appointment for product demo', sender: 'sales@company.com', recipients: ['customer3@example.com'], subject: 'Appointment Confirmation' },
  { id: 4, customer: 'Customer4', type: 'In-Person', content: 'Product demo', date: '2023-06-12T11:00:00', direction: 'Outgoing', summary: 'Conducted product demonstration', sender: 'sales@company.com', recipients: ['customer4@example.com'], subject: 'In-Store Product Demo' },
  { id: 5, customer: 'Customer5', type: 'Email', content: 'Follow-up', date: '2023-06-11T16:20:00', direction: 'Outgoing', summary: 'Sent follow-up email after product demo', sender: 'sales@company.com', recipients: ['customer5@example.com'], subject: 'Follow-up: Product Demo' },
];

const RecentTouchpoints = ({ searchTerm }) => {
  const filteredTouchpoints = useMemo(() => {
    return touchpoints.filter(touchpoint => 
      touchpoint.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
      touchpoint.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      touchpoint.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
      touchpoint.summary.toLowerCase().includes(searchTerm.toLowerCase()) ||
      touchpoint.subject.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Recent Touchpoints</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto -mx-4 sm:mx-0">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[80px] sm:w-[100px]">Customer</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Direction</TableHead>
                <TableHead className="hidden sm:table-cell">Subject</TableHead>
                <TableHead className="hidden md:table-cell">Summary</TableHead>
                <TableHead>Date</TableHead>
                <TableHead className="hidden lg:table-cell">Sender</TableHead>
                <TableHead className="hidden xl:table-cell">Recipients</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredTouchpoints.map((touchpoint) => (
                <TableRow key={touchpoint.id}>
                  <TableCell className="font-medium">{touchpoint.customer}</TableCell>
                  <TableCell>
                    <Badge variant="outline" className="whitespace-nowrap">{touchpoint.type}</Badge>
                  </TableCell>
                  <TableCell>
                    <Badge variant={touchpoint.direction === 'Incoming' ? 'default' : 'secondary'} className="whitespace-nowrap">
                      {touchpoint.direction}
                    </Badge>
                  </TableCell>
                  <TableCell className="hidden sm:table-cell">{touchpoint.subject}</TableCell>
                  <TableCell className="hidden md:table-cell">{touchpoint.summary}</TableCell>
                  <TableCell className="whitespace-nowrap">{new Date(touchpoint.date).toLocaleDateString()}</TableCell>
                  <TableCell className="hidden lg:table-cell">{touchpoint.sender}</TableCell>
                  <TableCell className="hidden xl:table-cell">{touchpoint.recipients.join(', ')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default RecentTouchpoints;

