'use client'

import * as React from "react"
import useEmblaCarousel from 'embla-carousel-react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { ContractorCard } from "./contractor-card"
import { Button } from "../../../components/ui/button"

export function LeadSourceCarousel({ source, leads, onLeadClick }) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    loop: false,
    dragFree: true,
    breakpoints: {
      '(min-width: 768px)': { slidesToScroll: 2 }
    }
  })

  const scrollPrev = React.useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi])
  const scrollNext = React.useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi])

  return (
    <div className="relative">
      <h3 className="text-xl font-semibold mb-4 text-gray-700">{source} Leads</h3>
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex">
          {leads.map((lead) => (
            <div key={lead.id} className="flex-[0_0_100%] md:flex-[0_0_50%] px-2">
              <ContractorCard 
                {...lead}
                onClick={() => onLeadClick(lead.id)}
              />
            </div>
          ))}
        </div>
      </div>
      <Button 
        variant="outline" 
        size="icon"
        className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-1/2 z-10"
        onClick={scrollPrev}
      >
        <ChevronLeft className="h-4 w-4" />
      </Button>
      <Button 
        variant="outline" 
        size="icon"
        className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2 z-10"
        onClick={scrollNext}
      >
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  )
}

