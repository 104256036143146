import React, { useState, useContext } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { CreateVoice } from "./create-voice";
import { CreateText } from "./create-text";
import { ActionCardsContext } from "contexts/ActionCardsContext";
import { Button } from "components/ui/button";
import { AppContext } from "contexts/AppContext";
import { toast } from "sonner";
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "components/ui/dialog";
import { Link } from "react-router-dom";

export function FollowupCard({
  card,
  getBadgeColor,
  selectedFollowup,
  handleClose = () => {},
}) {
  const { submitFollowup, updateActionCardStatus } = useContext(ActionCardsContext);
  const { salesAgentCD } = useContext(AppContext);

  const [errorMessage, setErrorMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);

  // State for follow-up fields
  const [content, setContent] = useState("");
  const [transcript, setTranscript] = useState(selectedFollowup?.transcript || "");
  const [type, setType] = useState(selectedFollowup?.type || "text");
  const [title, setTitle] = useState(selectedFollowup?.header || "");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isRecording) {
      setErrorMessage("Please stop the recording to process the text.");
      return;
    }

    if (!content) {
      setErrorMessage("Please add content");
      return;
    }

    setErrorMessage("");

    try {
      // Submit follow-up to the API
      await submitFollowup(card.action_id, content, card.custcd); // Use card.custcd for customer ID
      toast("Follow-up submitted", {
        description: "Your follow-up has been successfully submitted",
      });

      // Update card status to "logged"
      await updateActionCardStatus(card.action_id, "logged", card.custcd);

      // Close the modal after submission
      if (typeof handleClose === "function") {
        handleClose();
      }
    } catch (error) {
      console.error("Error submitting follow-up:", error);
      toast("Error submitting follow-up", {
        description: "An error occurred while submitting the follow-up",
      });
    }
  };

  return (
    <div className="flex h-full flex-col">
      <div className="p-4 sm:p-0">
        <DialogHeader>
          <DialogTitle>
            <Link to={`/customer/${card.custcd}`}>{card.customer_name}</Link>
          </DialogTitle>
          <DialogDescription className="py-4 text-md text-black">
            How did the outreach go?
          </DialogDescription>
        </DialogHeader>
        <form>
          <Tabs defaultValue="followup" className="w-full">
            <TabsList>
              <TabsTrigger value="followup">Text Input</TabsTrigger>
              <TabsTrigger value="voice">Voice Input</TabsTrigger>
            </TabsList>
            <TabsContent value="followup">
              <CreateText
                title={title}
                setTitle={setTitle}
                content={content}
                setContent={setContent}
              />
            </TabsContent>
            <TabsContent value="voice">
              <CreateVoice
                title={title}
                setTitle={setTitle}
                content={content}
                setContent={setContent}
                setType={setType}
                setTranscript={setTranscript}
                setIsRecording={setIsRecording}
                setErrorMessage={setErrorMessage}
                rows={8}
              />
            </TabsContent>
          </Tabs>
          {errorMessage && (
            <div className="text-red-600 text-sm mt-4">{errorMessage}</div>
          )}
          <div className="flex justify-end mt-4">
            <DialogClose asChild>
              <Button onClick={handleSubmit} size="sm" className="ml-2">
                Submit Follow-up
              </Button>
            </DialogClose>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FollowupCard;
