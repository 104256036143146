export const getAccessToken = (testing) => {
    if (testing) return "eyJraWQiOiJLTHpSQmN1XzVySHpmUVV2NXo5Q25CR0NpV1RrZ1g4ZEFrNjBKd3lTZ1djIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxMHZndW5vbXZ4cVEzaDJwOCIsIm5hbWUiOiJOYWRpYSBIb3NzYWluIiwiZW1haWwiOiJuYWRpYUBpbnN0YWxpbHkuYWkiLCJ2ZXIiOjEsImlzcyI6Imh0dHBzOi8vc3JzLm9rdGEuY29tL29hdXRoMi9hdXN2b2pndjl4dnpDU0RpWDJwNyIsImF1ZCI6IjBvYXY2eWFkMDdSa3NlNzVaMnA3IiwiaWF0IjoxNzM3NDcwNjc0LCJleHAiOjE3Mzc0NzQyNzQsImp0aSI6IklELnRDNDR0NHdqU1ViWjNMMGk4SG12X0RWUXNWRjZzaHEyT2w5amFFSmt5b3MiLCJhbXIiOlsibWZhIiwib3RwIiwicHdkIl0sImlkcCI6IjAwbzE2emp3ZWNDRnU3cFVqMnA3Iiwibm9uY2UiOiJISEF2aVJuckQxeEUyTThlUnJpSlF1WUN6UjJRVGtpWGc0NHdmUnRiRGZIWjJ4dVhzelFBUjZHYzRPM2FwQXlsIiwicHJlZmVycmVkX3VzZXJuYW1lIjoibmgxMTAwMzYiLCJhdXRoX3RpbWUiOjE3Mzc0NzA2NzIsImF0X2hhc2giOiI5bUl0azlLcVVNZmkxOVJVVzVzdm1RIn0.GYeTtsKH5lOJzD6BGfHgBbSrEQmfvGuKN-8iFmaIEQoR_iNN9Tv7v5B3WF5HjU2AGJF_blfARBaNvvDKXh27Mz2hieRrPTlMvLaLI9jCzEDXVzZmwbh-9Xo4rJXgcOymKHBT5EswP-XMH4zUMMcPCEBtot73CylZBgs2G2XmHHzc4JIG2hNmlhL7c-mGb_LEeASjeFNO5evxjlwxBDOYeTCqBKz5iGZYg8c-bHajtlWZhLn8qthaOiYr9Vp1Wk2WpVbLuM0zNkVLJruSHck4vBkkI0-bHOvlnhR-rPeCo-cYOoDcQr4fbk20tT2iPIijFWLcLdoxuiNjnyhRVJWUqQ"
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return oktaTokenStorage?.idToken?.idToken;
};

export const getLoggedInUser = async (testing) => {
  if (testing) return { userId: 'CC106472', firstName: 'Test', lastName: 'User' };

  const retryDelay = 500;
  const maxRetries = 5;

  // Helper function to wait for a specified duration
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to attempt fetching user data with retries
  const fetchFromOktaTokenStorage = async (retries) => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    // Ensure tokens are ready
    if (!oktaTokenStorage || !oktaTokenStorage.idToken) {
      if (retries > 0) {
        await delay(retryDelay); // Wait before retrying
        return fetchFromOktaTokenStorage(retries - 1); // Retry fetching
      }
      return null; // Return null if all retries are exhausted
    }

    const preferredUsername = oktaTokenStorage.idToken.claims?.preferred_username;
    const name = oktaTokenStorage.idToken.claims?.name;

    if (preferredUsername && name) {
      const [firstPart] = preferredUsername.split('@');
      const [firstName, lastName] = name.split(' ');
      return { userId: firstPart, firstName: firstName || '', lastName: lastName || '' };
    }

    return null;
  };

  return await fetchFromOktaTokenStorage(maxRetries);
};
