import React, { useState, useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { Switch } from "components/ui/switch"
import { Label } from "components/ui/label"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Sample revenue/customer data - replace with actual data from your backend
const revenueCustomerData = {
  revenue: {
    '2023-01-01': 5000, '2023-01-03': 5200, '2023-01-07': 5400, '2023-01-15': 5800,
    '2023-01-22': 6000, '2023-02-01': 6200, '2023-02-10': 6500, '2023-02-20': 6800,
    '2023-03-05': 7000, '2023-03-12': 7200, '2023-03-20': 7500, '2023-04-02': 7800,
    '2023-04-10': 8000, '2023-04-18': 8200, '2023-05-01': 8500, '2023-05-09': 8800,
    '2023-05-15': 9000, '2023-05-23': 9200, '2023-06-01': 9500
  },
  customers: {
    '2023-01-01': 100, '2023-01-03': 102, '2023-01-07': 105, '2023-01-15': 108,
    '2023-01-22': 110, '2023-02-01': 112, '2023-02-10': 115, '2023-02-20': 118,
    '2023-03-05': 120, '2023-03-12': 122, '2023-03-20': 125, '2023-04-02': 128,
    '2023-04-10': 130, '2023-04-18': 132, '2023-05-01': 135, '2023-05-09': 138,
    '2023-05-15': 140, '2023-05-23': 142, '2023-06-01': 145
  }
};

const rawJourneyData = [
  { date: '2023-01-01', Email: { count: 3, responded: 1 }, Phone: { count: 0, responded: 0 }, SMS: { count: 0, responded: 0 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-01-03', Email: { count: 2, responded: 2 }, Phone: { count: 1, responded: 0 }, SMS: { count: 0, responded: 0 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-01-07', Email: { count: 1, responded: 0 }, Phone: { count: 2, responded: 1 }, SMS: { count: 1, responded: 0 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-01-15', Email: { count: 2, responded: 1 }, Phone: { count: 4, responded: 2 }, SMS: { count: 0, responded: 0 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-01-22', Email: { count: 3, responded: 3 }, Phone: { count: 1, responded: 0 }, SMS: { count: 2, responded: 1 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-02-01', Email: { count: 4, responded: 2 }, Phone: { count: 1, responded: 1 }, SMS: { count: 2, responded: 0 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-02-10', Email: { count: 2, responded: 2 }, Phone: { count: 3, responded: 1 }, SMS: { count: 1, responded: 1 }, 'In-Person': { count: 1, responded: 0 } },
  { date: '2023-02-20', Email: { count: 1, responded: 0 }, Phone: { count: 0, responded: 0 }, SMS: { count: 0, responded: 0 }, 'In-Person': { count: 5, responded: 5 } },
  { date: '2023-03-05', Email: { count: 3, responded: 1 }, Phone: { count: 2, responded: 2 }, SMS: { count: 1, responded: 0 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-03-12', Email: { count: 2, responded: 2 }, Phone: { count: 1, responded: 0 }, SMS: { count: 3, responded: 1 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-03-20', Email: { count: 5, responded: 3 }, Phone: { count: 0, responded: 0 }, SMS: { count: 3, responded: 3 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-04-02', Email: { count: 1, responded: 0 }, Phone: { count: 2, responded: 2 }, SMS: { count: 0, responded: 0 }, 'In-Person': { count: 1, responded: 1 } },
  { date: '2023-04-10', Email: { count: 2, responded: 1 }, Phone: { count: 3, responded: 1 }, SMS: { count: 0, responded: 0 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-04-18', Email: { count: 3, responded: 3 }, Phone: { count: 1, responded: 0 }, SMS: { count: 2, responded: 1 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-05-01', Email: { count: 4, responded: 2 }, Phone: { count: 1, responded: 1 }, SMS: { count: 2, responded: 0 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-05-09', Email: { count: 2, responded: 2 }, Phone: { count: 2, responded: 1 }, SMS: { count: 1, responded: 0 }, 'In-Person': { count: 1, responded: 1 } },
  { date: '2023-05-15', Email: { count: 1, responded: 0 }, Phone: { count: 0, responded: 0 }, SMS: { count: 0, responded: 0 }, 'In-Person': { count: 4, responded: 4 } },
  { date: '2023-05-23', Email: { count: 3, responded: 1 }, Phone: { count: 1, responded: 0 }, SMS: { count: 2, responded: 2 }, 'In-Person': { count: 0, responded: 0 } },
  { date: '2023-06-01', Email: { count: 3, responded: 2 }, Phone: { count: 2, responded: 1 }, SMS: { count: 3, responded: 1 }, 'In-Person': { count: 0, responded: 0 } },
];

const CustomerJourneyMap = () => {
  const [binning, setBinning] = useState('day');
  const [showAggregate, setShowAggregate] = useState(false);
  const [metricType, setMetricType] = useState('revenue');
  const [showProjection, setShowProjection] = useState(false);

  // Sample projection data - replace with actual data from your backend
  const projectedImpact = {
    revenue: {
      '2024-06-15': 10000,
      '2024-07-01': 11000,
      '2024-07-15': 12000,
      '2024-08-01': 13000
    },
    customers: {
      '2024-06-15': 150,
      '2024-07-01': 155,
      '2024-07-15': 160,
      '2024-08-01': 165
    }
  };

  const binnedData = useMemo(() => {
    const binData = (data, binType) => {
      const bins = {};

      data.forEach(item => {
        const date = new Date(item.date);
        let binKey;

        if (binType === 'day') {
          binKey = item.date;
        } else if (binType === 'week') {
          const weekStart = new Date(date.setDate(date.getDate() - date.getDay()));
          binKey = weekStart.toISOString().split('T')[0];
        } else {
          binKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        }

        if (!bins[binKey]) {
          bins[binKey] = { 
            date: binKey, 
            Email: { count: 0, responded: 0 }, 
            Phone: { count: 0, responded: 0 }, 
            SMS: { count: 0, responded: 0 }, 
            'In-Person': { count: 0, responded: 0 },
            total: 0,
            revenue: revenueCustomerData.revenue[binKey] || 0,
            customers: revenueCustomerData.customers[binKey] || 0
          };
        }

        Object.keys(item).forEach(key => {
          if (key !== 'date') {
            bins[binKey][key].count += item[key].count;
            bins[binKey][key].responded += item[key].responded;
            bins[binKey].total += item[key].count;
          }
        });
      });

      // Add projection data if enabled
      if (showProjection) {
        Object.entries(projectedImpact[metricType]).forEach(([date, value]) => {
          bins[date] = {
            date,
            Email: { count: 0, responded: 0 },
            Phone: { count: 0, responded: 0 },
            SMS: { count: 0, responded: 0 },
            'In-Person': { count: 0, responded: 0 },
            total: 0,
            [metricType]: value,
            isProjected: true
          };
        });
      }

      return Object.values(bins).sort((a, b) => a.date.localeCompare(b.date));
    };

    return binData(rawJourneyData, binning);
  }, [binning, showProjection, metricType]);

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    if (binning === 'day') {
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    } else if (binning === 'week') {
      return `Week of ${date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`;
    } else {
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
    }
  };

  const formatMetricValue = (value) => {
    if (metricType === 'revenue') {
      return `$${value.toLocaleString()}`;
    }
    return value.toLocaleString();
  };

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0">
        <CardTitle>Customer Journey Map</CardTitle>
        <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
          <div className="flex items-center space-x-2">
            <Switch
              id="aggregate-mode"
              checked={showAggregate}
              onCheckedChange={setShowAggregate}
            />
            <Label htmlFor="aggregate-mode">Show Total</Label>
          </div>
          <div className="flex items-center space-x-2">
            <Switch
              id="projection-mode"
              checked={showProjection}
              onCheckedChange={setShowProjection}
            />
            <Label htmlFor="projection-mode">Show Projection</Label>
          </div>
          <Select value={metricType} onValueChange={setMetricType}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select metric" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="revenue">Revenue</SelectItem>
              <SelectItem value="customers">Customer Count</SelectItem>
            </SelectContent>
          </Select>
          <Select value={binning} onValueChange={(value) => setBinning(value)}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select binning" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="day">By Day</SelectItem>
              <SelectItem value="week">By Week</SelectItem>
              <SelectItem value="month">By Month</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </CardHeader>
      <CardContent>
        <div className="w-full h-[250px] sm:h-[300px] md:h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={binnedData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                tickFormatter={(value) => {
                  const date = new Date(value);
                  if (window.innerWidth < 640) {
                    return date.toLocaleDateString('en-US', { month: 'short' });
                  }
                  return formatXAxis(value);
                }}
              />
              <YAxis yAxisId="left" />
              <YAxis 
                yAxisId="right" 
                orientation="right" 
                domain={['auto', 'auto']}
                tickFormatter={formatMetricValue}
              />
              <Tooltip 
                labelFormatter={(value) => formatXAxis(value)}
                formatter={(value, name, props) => {
                  if (name === metricType) {
                    const isProjected = props.payload.isProjected;
                    const label = isProjected ? `${metricType === 'revenue' ? 'Projected Revenue' : 'Projected Customers'}` : 
                      (metricType === 'revenue' ? 'Revenue' : 'Customers');
                    return [formatMetricValue(value), label];
                  }
                  if (name === 'Total') return [value, 'Total Touchpoints'];
                  return [value, name];
                }}
              />
              <Legend />
              {/* Metric Line */}
              <Line
                yAxisId="right"
                type="monotone"
                dataKey={metricType}
                name={metricType === 'revenue' ? 'Revenue' : 'Customers'}
                stroke="rgba(100, 116, 139, 0.3)"
                strokeWidth={1.5}
                dot={(props) => {
                  const { cx, cy, payload } = props;
                  if (payload.isProjected) return null;
                  return (
                    <circle 
                      cx={cx} 
                      cy={cy} 
                      r={2}
                      fill="rgba(100, 116, 139, 0.3)"
                      stroke="rgba(100, 116, 139, 0.5)"
                    />
                  );
                }}
                activeDot={(props) => {
                  const { cx, cy, payload } = props;
                  if (payload.isProjected) return null;
                  return (
                    <circle 
                      cx={cx} 
                      cy={cy} 
                      r={6}
                      fill="rgba(100, 116, 139, 0.5)"
                      stroke="rgba(100, 116, 139, 0.8)"
                    />
                  );
                }}
                strokeDasharray="4 2"
              />
              {/* Projection Line */}
              {showProjection && (
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey={(data) => data.isProjected ? data[metricType] : null}
                  name={`Projected ${metricType === 'revenue' ? 'Revenue' : 'Customers'}`}
                  stroke="rgba(99, 102, 241, 0.5)"
                  strokeWidth={1.5}
                  strokeDasharray="6 4"
                  dot={{ r: 3, fill: "rgba(99, 102, 241, 0.5)", stroke: "rgba(99, 102, 241, 0.8)" }}
                  activeDot={{ r: 6, fill: "rgba(99, 102, 241, 0.7)", stroke: "rgba(99, 102, 241, 1)" }}
                />
              )}
              {/* Touchpoint Lines */}
              {showAggregate ? (
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="total"
                  name="Total"
                  stroke="#8884d8"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 8 }}
                />
              ) : (
                <>
                  <Line 
                    yAxisId="left"
                    type="monotone" 
                    dataKey={(data) => data.Email.count}
                    name="Email"
                    stroke="#8884d8" 
                    strokeWidth={2}
                    dot={(props) => {
                      const { cx, cy, payload } = props;
                      const isFilled = payload.Email.responded === payload.Email.count && payload.Email.count > 0;
                      return (
                        <circle 
                          cx={cx} 
                          cy={cy} 
                          r={4}
                          stroke="#8884d8"
                          strokeWidth={2}
                          fill={isFilled ? "#8884d8" : "#fff"}
                        />
                      );
                    }}
                    activeDot={{ r: 8 }}
                  />
                  <Line 
                    yAxisId="left"
                    type="monotone" 
                    dataKey={(data) => data.Phone.count}
                    name="Phone"
                    stroke="#82ca9d" 
                    strokeWidth={2}
                    dot={(props) => {
                      const { cx, cy, payload } = props;
                      const isFilled = payload.Phone.responded === payload.Phone.count && payload.Phone.count > 0;
                      return (
                        <circle 
                          cx={cx} 
                          cy={cy} 
                          r={4}
                          stroke="#82ca9d"
                          strokeWidth={2}
                          fill={isFilled ? "#82ca9d" : "#fff"}
                        />
                      );
                    }}
                    activeDot={{ r: 8 }}
                  />
                  <Line 
                    yAxisId="left"
                    type="monotone" 
                    dataKey={(data) => data.SMS.count}
                    name="SMS"
                    stroke="#ffc658" 
                    strokeWidth={2}
                    dot={(props) => {
                      const { cx, cy, payload } = props;
                      const isFilled = payload.SMS.responded === payload.SMS.count && payload.SMS.count > 0;
                      return (
                        <circle 
                          cx={cx} 
                          cy={cy} 
                          r={4}
                          stroke="#ffc658"
                          strokeWidth={2}
                          fill={isFilled ? "#ffc658" : "#fff"}
                        />
                      );
                    }}
                    activeDot={{ r: 8 }}
                  />
                  <Line 
                    yAxisId="left"
                    type="monotone" 
                    dataKey={(data) => data['In-Person'].count}
                    name="In-Person"
                    stroke="#ff7300" 
                    strokeWidth={2}
                    dot={(props) => {
                      const { cx, cy, payload } = props;
                      const isFilled = payload['In-Person'].responded === payload['In-Person'].count && payload['In-Person'].count > 0;
                      return (
                        <circle 
                          cx={cx} 
                          cy={cy} 
                          r={4}
                          stroke="#ff7300"
                          strokeWidth={2}
                          fill={isFilled ? "#ff7300" : "#fff"}
                        />
                      );
                    }}
                    activeDot={{ r: 8 }}
                  />
                </>
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default CustomerJourneyMap;

