import React, { useState, useContext, useRef } from "react";
import { ActionCardsContext } from "contexts/ActionCardsContext";
import { TouchpointContext } from "contexts/TouchpointContext";
import { v4 as uuidv4 } from "uuid";

import {
  Calendar,
  Mail,
  Phone,
  MessageCircle,
  X,
  MessageSquareQuote,
} from "lucide-react";

import { Badge } from "components/catalyst/badge";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/ui/carousel";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { ScrollArea } from "components/ui/scroll-area";
import { Textarea } from "components/ui/textarea";
import { ToggleGroup, ToggleGroupItem } from "components/ui/toggle-group";
import { Bold, Italic, Underline } from "lucide-react";
import CalendarEvent from "../customer-template/CalendarEvent";
import ActionCardsEmail from "components/action-cards-component/action-cards-email";
import { Link } from "react-router-dom";
import { ThumbsDown, ThumbsUp } from "lucide-react";
import FollowupCard from "components/action-cards-component/followup-card";
import { toast } from "sonner";

export const getActionItemsCount = () => {
  const { actionCards } = useContext(ActionCardsContext);
  const filteredCards = actionCards.filter(
    (card) => card.status !== "ignored" && card.status !== "logged"
  );
  return filteredCards.length;
};

const colorMapBackground = {
  "Delayed Delivery": "orange",
  "Churn Risk": "red",
  "Purchase Potential": "green",
  "Order Inactivity": "blue",
  "Upsell Opportunity": "purple",
  "Purchase Frequency Drop": "amber",
  "Low Digital Revenue Pct": "teal",
  "Late Payments": "pink",
  "Net New Customer": "yellow",
  "Customer Journey": "indigo",
  "New Customer Promo": "cyan",
};

const getBadgeColor = (label) => {
  return colorMapBackground[label] || "zinc"; // Fallback to gray if not found
};

export default function ActionCards() {
  const { actionCards, updateActionCardStatus, submitFeedback } =
    useContext(ActionCardsContext);
  const { addTouchpoint } = useContext(TouchpointContext);

  const handleStatusUpdate = (card, status) => {
    // Pass card.custcd along with actionId and status
    updateActionCardStatus(card.action_id, status, card.custcd)
      .then(() => {
        console.log(
          `Action Card ${card.action_id} updated to status: ${status}`
        );
      })
      .catch((err) => {
        console.error(
          `Failed to update status for Action Card ${card.action_id}:`,
          err
        );
      });
  };

  const handleCreateTouchpoint = async (card) => {
    console.log("handleCreateTouchpoint invoked with card:", card);
    const touchpointDate = new Date(); // Example, you can adjust based on your logic
    const formattedDate =
      touchpointDate.toISOString().slice(0, 19) +
      "." +
      touchpointDate.getMilliseconds().toString().padStart(3, "0") +
      "000";

    const createdDate = new Date();
    const formattedCreatedDate =
      createdDate.toISOString().slice(0, 19) +
      "." +
      createdDate.getMilliseconds().toString().padStart(3, "0") +
      "000";

    const metadata =
      card.outreach_method === "email"
        ? {
            tp_type: "email",
            subject: null,
            ccs: null,
            bccs: null,
            num_attachments: null,
            senders: null,
            recipients: null,
            content: card.outreach_payload,
            disposition: null,
          }
        : card.outreach_method === "phone"
        ? {
            tp_type: "call",
            participants: null,
            duration: null,
            num_participants: null,
            has_recording: null,
            disposition: null,
            content: card.outreach_payload,
          }
        : card.outreach_method === "sms"
        ? {
            tp_type: "text",
            senders: null,
            recipients: null,
            content: card.outreach_payload,
            disposition: null,
          }
        : card.outreach_method === "event"
        ? {
            tp_type: "in-person",
            duration: null,
            num_participants: null,
            participants: null,
            location: null,
            disposition: null,
            content: card.outreach_payload,
          }
        : {};

    const touchpointData = {
      touchpointsid: uuidv4(),
      custcd: card.custcd,
      custname: card.customer_name,
      tp_type: metadata.tp_type,
      summary: "",
      touchpoint_date: formattedDate,
      system_generated: true,
      visible: true,
      bookmarked: false,
      status: "completed",
      impact: 0,
      response_status: false,
      created_date: formattedCreatedDate,
      metadata: metadata,
    };

    console.log("Touchpoint data being sent:", touchpointData);
    // console.log("Form submitted with data:", {
    //   selectedCustomer,
    //   touchpointType,
    //   content,
    // });

    try {
      await addTouchpoint(touchpointData);
      toast.success("Touchpoint added successfully!");
    } catch (err) {
      console.error("Error adding touchpoint:", err);
      toast.error("Failed to add touchpoint. Please try again.");
    }
  };

  const handleFeedback = async (card, rating) => {
    try {
      await submitFeedback(card.action_id, rating);
      console.log(
        `Feedback submitted for action card ${card.action_id}: ${rating}`
      );
      toast("Your feedback has been submitted.");
    } catch (error) {
      console.error(
        `Error submitting feedback for action card ${card.action_id}:`,
        error
      );
    }
  };

  const ActionCard = ({ card }) => {
    const calendarEventRef = useRef(null);
    const emailRef = useRef(null);
    return (
      <Card className="flex flex-col h-full w-[350px]">
        <CardHeader>
          <div className="flex justify-between items-start">
            <div>
              <CardTitle className="text-md">
                <Link to={`/customer/${card.custcd}`}>
                  {card.customer_name}
                </Link>
              </CardTitle>
              <div className="flex items-center gap-2 text-sm text-muted-foreground mt-1 flex-wrap">
                {card.flags.slice(0, 3).map((flag) => {
                  const badgeColor = getBadgeColor(flag);

                  return (
                    <Badge
                      key={flag}
                      color={badgeColor}
                      className="shrink-0 truncate"
                    >
                      {flag}
                    </Badge>
                  );
                })}
                {card.flags.length > 3 && (
                  <Badge color="gray" className="shrink-0 truncate">
                    +{card.flags.length - 3}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        </CardHeader>
        <CardContent className="flex-grow">
          {card.status === "actioned" ? ( // Check if the card is actioned
            <div className="h-[200px] overflow-y-scroll">
              <p className="text-base mb-4">{card.action_output}</p>
            </div>
          ) : (
            <div className="h-[200px]">
              <p className="text-base mb-4">
                {card.action_output.length > 275
                  ? `${card.action_output.substring(0, 275)}...`
                  : card.action_output}
              </p>
            </div>
          )}
        </CardContent>
        <CardFooter className="flex flex-col">
          <Dialog>
            <DialogTrigger asChild>
              <Button
                size="sm"
                className="bg-blue-600 text-white hover:bg-blue-700 w-full"
                onClick={() => {
                  if (card.status === null) {
                    handleStatusUpdate(card, "reviewed");
                  }
                }}
              >
                {card.status === "actioned" ? (
                  <>
                    <MessageSquareQuote className="mr-2 h-4 w-4" />
                    Record Follow-Up
                  </>
                ) : card.outreach_method === "phone" ? (
                  <>
                    <Phone className="mr-2 h-4 w-4" />
                    Review Call Actions
                  </>
                ) : card.outreach_method === "email" ? (
                  <>
                    <Mail className="mr-2 h-4 w-4" />
                    Review Email Actions
                  </>
                ) : card.outreach_method === "event" ? (
                  <>
                    <Calendar className="mr-2 h-4 w-4" />
                    Review Meeting Actions
                  </>
                ) : card.outreach_method === "sms" ? (
                  <>
                    <MessageCircle className="mr-2 h-4 w-4" />
                    Review SMS Actions
                  </>
                ) : null}
              </Button>
            </DialogTrigger>
            <DialogContent className="w-full sm:max-w-[600px] p-6">
              {card.status === "actioned" ? (
                // {card.status === 2 ? (
                // Render alternate content for status 2
                <FollowupCard card={card} getBadgeColor={getBadgeColor} />
              ) : (
                <>
                  <DialogHeader>
                    <DialogTitle>
                      <Link to={`/customer/${card.custcd}`}>
                        {card.customer_name}
                      </Link>
                    </DialogTitle>
                    <DialogTitle>
                      <div className="flex items-center gap-2 text-sm text-muted-foreground mt-1 flex-wrap">
                        {card.flags.map((flag) => {
                          const formattedFlag = flag
                            .replace(/_flag/g, "")
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (char) => char.toUpperCase());

                          const badgeColor = getBadgeColor(formattedFlag);

                          return (
                            <Badge
                              key={flag}
                              color={badgeColor}
                              className="text-xs"
                            >
                              {formattedFlag}
                            </Badge>
                          );
                        })}
                      </div>
                    </DialogTitle>
                    <DialogDescription className="pt-4">
                    <span style={{ fontWeight: "bold", }}>Overview: </span>

                      {card.action_output}
                    </DialogDescription>
                  </DialogHeader>
                  {card.outreach_method === "phone" && (
                    <div className="grid gap-4 py-4 h-auto">
                      <Textarea
                        rows={12}
                        defaultValue={card.outreach_payload}
                      />
                    </div>
                  )}
                  {card.outreach_method === "sms" && (
                    <div className="grid gap-4 py-4">
                      <Textarea rows={6} defaultValue={card.outreach_payload} />
                    </div>
                  )}
                  {card.outreach_method === "event" && (
                    <div className="grid gap-4 py-4">
                      <CalendarEvent
                        ref={calendarEventRef}
                        meetingDetails={card.outreach_payload}
                        action_id={card.action_id}
                      />
                    </div>
                  )}
                  {card.outreach_method === "email" && (
                    <ActionCardsEmail
                      ref={emailRef}
                      outreachPayload={card.outreach_payload}
                      card={card}
                    />
                  )}
                  <div className="flex items-center justify-between mb-4">
                    <span>Was this a helpful action item?</span>
                    <div className="flex gap-2">
                      {/* Thumbs Up Button */}
                      <button
                        aria-label="Thumb Up"
                        className={`text-gray-500 hover:text-gray-700 active:text-gray-700 focus:outline-none`}
                        onClick={() => handleFeedback(card, true)}
                      >
                        <ThumbsUp
                          className="w-5 h-5 mr-1 transition-colors duration-200"
                          // fill={
                          //   card.feedback === true ? "currentColor" : "none"
                          // }
                        />
                      </button>

                      {/* Thumbs Down Button */}
                      <button
                        aria-label="Thumb Down"
                        className={`text-gray-500 hover:text-gray-700 active:text-gray-700 focus:outline-none`}
                        onClick={() => handleFeedback(card, false)}
                      >
                        <ThumbsDown
                          className="w-5 h-5 mr-1 transition-colors duration-200"
                          // fill={
                          //   card.feedback === false ? "currentColor" : "none"
                          // }
                        />
                      </button>
                    </div>
                  </div>

                  <DialogFooter className="flex flex-col w-full">
                    <Button
                      onClick={() => handleStatusUpdate(card, "ignored")}
                      variant="outline"
                      className="w-full text-rose-500 mb-2" // Add margin for spacing between the buttons
                    >
                      <X className="mr-2 h-4 w-4" />
                      Dismiss
                    </Button>

                    <Button
                      type="submit"
                      className="w-full"
                      onClick={() => {
                        if (card.outreach_method === "event") {
                          // Ensure title and date are not empty
                          const title = calendarEventRef.current?.title; // Access the title directly
                          const selectedDate =
                            calendarEventRef.current?.selectedDate; // Access the selected date directly

                          if (!title || !selectedDate) {
                            const missingFields = [];
                            if (!title) missingFields.push("title");
                            if (!selectedDate) missingFields.push("date");

                            toast.error(
                              `Please add ${missingFields.join(" and ")}.`
                            );
                            return; // Prevent further action if fields are missing
                          }

                          // If validation passes, submit the calendar event
                          calendarEventRef.current
                            ?.handleSubmit()
                            .then((isSuccess) => {
                              if (isSuccess) {
                                // Only update status and create touchpoint if the event is successfully created
                                handleStatusUpdate(card, "actioned");
                                handleCreateTouchpoint(card);
                              } else {
                                toast.error("Error creating calendar event");
                              }
                            });
                            return;
                        }
                        if (card.outreach_method === "email") {
                          // Get the "To" field value from the email component
                          const emailToField =
                            emailRef.current?.getToFieldValue();

                          if (!emailToField || emailToField.trim() === "") {
                            // If the "To" field is empty, show an error message
                            toast.error(
                              "Please enter a recipient's email address."
                            );
                            return; // Prevent further action if "To" is empty
                          }
                          // Show confirmation dialog
                          const isConfirmed = window.confirm(
                            "Are you sure you want to send this message?"
                          );

                          if (isConfirmed) {
                            // If the user confirms, proceed with the email submission
                            emailRef.current
                              ?.handleSubmit()
                              .then((isSuccess) => {
                                if (isSuccess) {
                                  // Only update status and create touchpoint if the email is successfully sent
                                  handleStatusUpdate(card, "actioned");
                                  handleCreateTouchpoint(card);
                                } else {
                                  toast.error("Error sending email");
                                }
                              });
                              return;
                          }
                        }
                        handleStatusUpdate(card, "actioned");
                        handleCreateTouchpoint(card);
                      }}
                    >
                      {card.outreach_method === "phone"
                        ? "Mark Completed"
                        : card.outreach_method === "email"
                        ? "Send Email"
                        : card.outreach_method === "sms"
                        ? "Mark Completed"
                        : "Create Event"}
                    </Button>
                  </DialogFooter>
                </>
              )}
            </DialogContent>
          </Dialog>
        </CardFooter>
      </Card>
    );
  };

  return (
    <div className="w-full mx-auto px-4 relative overflow-visible">
      <Carousel
        opts={{
          align: "start",
          loop: true,
          containScroll: "trimSnaps",
        }}
        className="w-full relative"
      >
        <CarouselContent className="px-4">
          {actionCards.map(
            (card, index) =>
              card.status !== "ignored" &&
              card.status !== "logged" && (
                <CarouselItem key={card.action_id} className="basis-auto">
                  <div className="p-1">
                    <ActionCard card={card} />
                  </div>
                </CarouselItem>
              )
          )}
        </CarouselContent>
        <CarouselPrevious className="absolute -left-4 top-1/2 -translate-y-1/2 h-8 w-8 bg-white shadow-md hover:bg-gray-50" />
        <CarouselNext className="absolute -right-4 top-1/2 -translate-y-1/2 h-8 w-8 bg-white shadow-md hover:bg-gray-50" />
      </Carousel>
      <div className="mt-4 text-center">
        <Link
          to="/customers"
          className="text-sm text-blue-600 hover:text-blue-800 hover:underline"
        >
          View All Customers →
        </Link>
      </div>
    </div>
  );
}
