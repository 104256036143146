import React, { useContext, useEffect, useState } from "react";
import { TouchpointContext } from "contexts/TouchpointContext";
import { Card, CardContent, CardHeader } from "components/ui/card";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Check, Mail, Phone, MessageSquare, Users, Send } from "lucide-react";
import { ScrollArea } from "components/ui/scroll-area";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { Textarea } from "components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Frown, Meh, Smile } from "lucide-react";
import { Link } from "react-router-dom";

const getTypeIcon = (type) => {
  switch (type.toLowerCase()) {
    case "email":
      return <Mail className="h-4 w-4" />;
    case "call":
      return <Phone className="h-4 w-4" />;
    case "in-person":
      return <Users className="h-4 w-4" />;
    default:
      return <MessageSquare className="h-4 w-4" />;
  }
};

const EmailDraftDialog = ({ touchpoint, onClose }) => {
  const [selectedISR, setSelectedISR] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  // Sample ISR data - replace with actual data
  const isrList = [
    { id: "isr1", name: "John Smith", email: "john.smith@company.com" },
    { id: "isr2", name: "Jane Doe", email: "jane.doe@company.com" },
    { id: "isr3", name: "Bob Johnson", email: "bob.johnson@company.com" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle email submission here
    console.log("Email draft submitted", {
      touchpoint,
      selectedISR,
      emailSubject,
      emailBody,
    });
    onClose();
  };

  const generateDefaultEmailBody = (touchpoint) => {
    const summaries = touchpoint.content
      .map((item) => `- ${item.summary}`)
      .join("\\n");
    return `Hi,\\n\\nI wanted to bring your attention to some recent interactions with ${touchpoint.recipient.name}:\\n\\n${summaries}\\n\\nBest regards,`;
  };

  return (
    <DialogContent className="sm:max-w-[600px]">
      <DialogHeader>
        <DialogTitle>Draft Email to ISR</DialogTitle>
      </DialogHeader>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="isr">Select ISR</Label>
          <Select value={selectedISR} onValueChange={setSelectedISR}>
            <SelectTrigger>
              <SelectValue placeholder="Choose an ISR" />
            </SelectTrigger>
            <SelectContent>
              {isrList.map((isr) => (
                <SelectItem key={isr.id} value={isr.id}>
                  {isr.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="space-y-2">
          <Label htmlFor="subject">Subject</Label>
          <Input
            id="subject"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            placeholder="Email subject"
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="body">Message</Label>
          <Textarea
            id="body"
            value={emailBody || generateDefaultEmailBody(touchpoint)}
            onChange={(e) => setEmailBody(e.target.value)}
            placeholder="Email content"
            rows={10}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <Button type="button" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={!selectedISR || !emailSubject}>
            Send Draft
          </Button>
        </div>
      </form>
    </DialogContent>
  );
};

const ViewDetailsDialog = ({ touchpoint, onClose, onUpdate, onDelete }) => {
  const [updatedStatus, setUpdatedStatus] = useState(touchpoint.status || "");
  const [selectedDisposition, setSelectedDisposition] = useState(
    touchpoint.metadata.disposition || "neutral"
  );

  const statusOptions = [
    { label: "Completed", value: "completed" },
    { label: "Scheduled", value: "scheduled" },
  ];

  useEffect(() => {
    setUpdatedStatus(touchpoint.status || "");
    setSelectedDisposition(touchpoint.metadata.disposition || "neutral");
  }, [touchpoint]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct the metadata based on tp_type
    let metadata;

    switch (touchpoint.tp_type) {
      case "email":
        metadata = {
          tp_type: "email",
          subject: touchpoint.metadata.subject || "", // Set the subject if available
          ccs: touchpoint.metadata.ccs || [], // Set CCs if available
          bccs: touchpoint.metadata.bccs || [], // Set BCCs if available
          num_attachments: touchpoint.metadata.num_attachments || 0, // Set number of attachments
          senders: touchpoint.metadata.senders || [], // Set senders
          recipients: touchpoint.metadata.recipients || [], // Set recipients
          content: touchpoint.metadata.content, // Set content, fallback to metadata if available
          disposition: selectedDisposition || "neutral", // Set disposition (e.g., neutral)
        };
        break;
      case "call":
        metadata = {
          tp_type: "call",
          participants: touchpoint.metadata.participants || [], // Set participants
          duration: touchpoint.metadata.duration || 0, // Set duration
          num_participants: touchpoint.metadata.num_participants || 0, // Set num_participants
          has_recording: touchpoint.metadata.has_recording || false, // Set has_recording
          content: touchpoint.metadata.content, // Set content, fallback to metadata if available
          disposition: selectedDisposition || "neutral", // Set disposition
        };
        break;
      case "text":
        metadata = {
          tp_type: "text",
          senders: touchpoint.metadata.senders || [], // Set senders
          recipients: touchpoint.metadata.recipients || [], // Set recipients
          content: touchpoint.metadata.content, // Set content, fallback to metadata if available
          disposition: selectedDisposition || "neutral", // Set disposition
        };
        break;
      case "in-person":
        metadata = {
          tp_type: "in-person",
          duration: touchpoint.metadata.duration || 0, // Set duration
          num_participants: touchpoint.metadata.num_participants || 0, // Set num_participants
          participants: touchpoint.metadata.participants || [], // Set participants
          location: touchpoint.metadata.location || "", // Set location
          content: touchpoint.metadata.content, // Set content, fallback to metadata if available
          disposition: selectedDisposition || "neutral", // Set disposition
        };
        break;
      default:
        metadata = {}; // Fallback if no valid tp_type
        break;
    }

    // Construct the full payload
    const payload = {
      ...touchpoint,
      status: updatedStatus || touchpoint.status, // Update the status, fallback to existing status
      updated_date: new Date().toISOString(),
      metadata: metadata, // Include the constructed metadata
      custcd: touchpoint.custcd,
    };

    console.log("Payload:", payload); // Log the payload

    try {
      await onUpdate(payload);
      console.log("Touchpoint successfully updated");
      onClose();
    } catch (err) {
      console.error("Error updating touchpoint:", err);
    }
  };

  const handleDelete = async () => {
    try {
      const payload = {
        ...touchpoint,
        updated_date: new Date().toISOString(),
      };
      await onDelete(payload);
      console.log("Touchpoint successfully deleted");
      onClose();
    } catch (err) {
      console.error("Error deleting touchpoint:", err);
    }
  };

  return (
    <DialogContent className="sm:max-w-[600px]">
      <DialogHeader>
        <DialogTitle>
          <Link to={`/customer/${touchpoint.custcd}`}>
            {touchpoint.custname}
          </Link>
        </DialogTitle>
      </DialogHeader>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="content">Touchpoint Content</Label>
          <div className="text-gray-700 text-sm">
            {touchpoint.metadata.content}
          </div>
        </div>

        <div className="space-y-2 gap-2">
          <Label>Client Disposition</Label>
          <div className="flex gap-4 items-center">
            <div className="flex items-center">
              <input
                type="radio"
                id="frown"
                name="disposition"
                value="negative"
                checked={selectedDisposition === "negative"}
                onChange={() => setSelectedDisposition("negative")}
                className="h-4 w-4"
              />
              <label htmlFor="frown" className="ml-2">
                {/* <Frown className="h-8 w-8" /> */}
                negative
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="meh"
                name="disposition"
                value="neutral"
                checked={selectedDisposition === "neutral"}
                onChange={() => setSelectedDisposition("neutral")}
                className="h-4 w-4"
              />
              <label htmlFor="meh" className="ml-2">
                {/* <Meh className="h-8 w-8" /> */}
                neutral
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="smile"
                name="disposition"
                value="positive"
                checked={selectedDisposition === "positive"}
                onChange={() => setSelectedDisposition("positive")}
                className="h-4 w-4"
              />
              <label htmlFor="smile" className="ml-2">
                positive
                {/* <Smile className="h-8 w-8" /> */}
              </label>
            </div>
          </div>
        </div>
        <div className="space-y-2 gap-2">
          <Label>Status</Label>
          <div className="flex gap-4 items-center">
            <div className="flex items-center">
              <input
                type="radio"
                id="completed"
                name="status"
                value="completed"
                checked={updatedStatus === "completed"}
                onChange={() => setUpdatedStatus("completed")}
                className="h-4 w-4"
              />
              <label htmlFor="completed" className="ml-2">
                completed
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="scheduled"
                name="status"
                value="scheduled"
                checked={updatedStatus === "scheduled"}
                onChange={() => setUpdatedStatus("scheduled")}
                className="h-4 w-4"
              />
              <label htmlFor="scheduled" className="ml-2">
                scheduled
              </label>
            </div>
          </div>
        </div>

        <div className="flex space-x-2">
          <Button
            className="w-full"
            type="button"
            variant="outline"
            onClick={handleDelete}
          >
            Delete Touchpoint
          </Button>
          <Button
            className="w-full"
            type="submit"
            disabled={!selectedDisposition || !updatedStatus}
          >
            Update Touchpoint
          </Button>
        </div>
      </form>
    </DialogContent>
  );
};

const TouchpointsList = ({ custcd }) => {
  const { touchpoints, updateTouchpoint, deleteTouchpoint } =
    useContext(TouchpointContext);
  const [openDialogId, setOpenDialogId] = useState(null);

  return (
    <ScrollArea className="h-[calc(100vh-200px)] max-h-[440px]">
      <div className="space-y-4">
        {touchpoints.map((touchpoint) => (
          <Card key={touchpoint.touchpointsid} className="w-full">
            <CardHeader className="flex flex-row items-center justify-between p-4">
              <div className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2">
                  {getTypeIcon(touchpoint.tp_type)}
                  <span className="text-sm text-muted-foreground">
                    {new Date(touchpoint.touchpoint_date).toLocaleDateString(
                      "en-US",
                      {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  {/* <span className="font-semibold">{touchpoint.id}</span>
                  <span className="text-sm text-muted-foreground">Sent to</span>
                  <Avatar className="h-6 w-6">
                    <AvatarImage src={touchpoint.recipient.avatar} />
                    <AvatarFallback>{touchpoint.recipient.name.split(' ').map(n => n[0]).join('')}</AvatarFallback>
                  </Avatar> */}
                  <span className="font-medium text-sm">
                    <Link to={`/customer/${touchpoint.custcd}`}>
                      {touchpoint.custname}
                    </Link>
                  </span>
                </div>
              </div>
                {touchpoint.metadata?.content && (
                  <p className="text-sm text-muted-foreground hidden md:block">
                    {touchpoint.metadata.content.slice(0, 50)}
                    {touchpoint.metadata.content.length > 50 ? "..." : ""}
                  </p>
                )}
              <div className="flex items-center space-x-2">
                {/* <Dialog open={openDialogId === touchpoint.id} onOpenChange={(open) => setOpenDialogId(open ? touchpoint.id : null)}>
                  <DialogTrigger asChild>
                    <Button variant="outline" size="sm" className="flex items-center space-x-1">
                      <Send className="h-4 w-4" />
                      <span>Draft to ISR</span>
                    </Button>
                  </DialogTrigger>
                  <EmailDraftDialog 
                    touchpoint={touchpoint} 
                    onClose={() => setOpenDialogId(null)}
                  />
                </Dialog> */}
                <Dialog
                  open={openDialogId === touchpoint.touchpointsid}
                  onOpenChange={(open) =>
                    setOpenDialogId(open ? touchpoint.touchpointsid : null)
                  }
                >
                  <DialogTrigger asChild>
                    <Button variant="outline" size="sm">
                      View More
                    </Button>
                  </DialogTrigger>
                  <ViewDetailsDialog
                    touchpoint={touchpoint}
                    onClose={() => setOpenDialogId(null)}
                    onUpdate={updateTouchpoint}
                    onDelete={deleteTouchpoint}
                  />
                </Dialog>
              </div>
            </CardHeader>
            {/* <CardContent className="p-0">
              <div className="border-t">
                <table className="w-full">
                  <thead className="bg-muted/50">
                    <tr className="text-sm">
                      <th className="py-2 px-4 text-left font-medium">
                        Summary
                      </th>
                      <th className="py-2 px-4 text-left font-medium w-[100px]">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-t">
                      <td className="py-2 px-4">
                        <div className="flex items-start space-x-2">
                          <span className="text-sm">{touchpoint.summary}</span>
                        </div>
                      </td>
                      <td className="py-2 px-4 text-sm text-muted-foreground">
                          {touchpoint.touchpoint_date}
                        </td>
                      <td className="py-2 px-4">
                        <Badge
                          variant="outline"
                          className="bg-green-50 text-green-700 border-green-200"
                        >
                          {touchpoint.status}
                        </Badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardContent> */}
          </Card>
        ))}
      </div>
    </ScrollArea>
  );
};

export default TouchpointsList;
