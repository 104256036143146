import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line } from 'recharts';
import { Activity, Users, Clock, ThumbsUp, BarChart2, PieChartIcon, TrendingUp } from 'lucide-react'

const touchpointData = [
  { name: 'Jan', Email: 400, Phone: 240, SMS: 200, 'In-Person': 80 },
  { name: 'Feb', Email: 300, Phone: 300, SMS: 250, 'In-Person': 100 },
  { name: 'Mar', Email: 600, Phone: 400, SMS: 300, 'In-Person': 150 },
  { name: 'Apr', Email: 800, Phone: 350, SMS: 350, 'In-Person': 120 },
  { name: 'May', Email: 500, Phone: 450, SMS: 400, 'In-Person': 200 },
  { name: 'Jun', Email: 700, Phone: 500, SMS: 450, 'In-Person': 180 },
];

const communicationData = [
  { name: 'Email', value: 3300 },
  { name: 'Phone', value: 2240 },
  { name: 'SMS', value: 1950 },
  { name: 'In-Person', value: 830 },
];

const responseTimeData = [
  { name: 'Mon', time: 2.5 },
  { name: 'Tue', time: 2.2 },
  { name: 'Wed', time: 2.8 },
  { name: 'Thu', time: 2.1 },
  { name: 'Fri', time: 2.4 },
  { name: 'Sat', time: 3.2 },
  { name: 'Sun', time: 3.5 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Dashboard = () => {
  return (
    <div className="space-y-4">
      <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Touchpoints</CardTitle>
            <Activity className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">8,320</div>
            <p className="text-xs text-muted-foreground">+20.1% from last month</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Active Customers</CardTitle>
            <Users className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">1,250</div>
            <p className="text-xs text-muted-foreground">+15% from last month</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Avg. Response Time</CardTitle>
            <Clock className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">2.5 hours</div>
            <p className="text-xs text-muted-foreground">-30 minutes from last month</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Customer Satisfaction</CardTitle>
            <ThumbsUp className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">4.8/5</div>
            <p className="text-xs text-muted-foreground">+0.3 from last month</p>
          </CardContent>
        </Card>
      </div>

      <Tabs defaultValue="touchpoints" className="space-y-4">
        <TabsList className="w-full grid grid-cols-2 rounded-none bg-muted p-1 text-muted-foreground">
          <TabsTrigger value="touchpoints" className="rounded-md data-[state=active]:bg-background data-[state=active]:text-foreground">
            <div className="flex flex-col items-center sm:flex-row sm:justify-center gap-1 py-2 px-1 sm:py-1 sm:px-2">
              <BarChart2 className="h-4 w-4 sm:mr-2" />
              <span className="text-xs sm:text-sm">Analysis</span>
            </div>
          </TabsTrigger>
          <TabsTrigger value="communication" className="rounded-md data-[state=active]:bg-background data-[state=active]:text-foreground">
            <div className="flex flex-col items-center sm:flex-row sm:justify-center gap-1 py-2 px-1 sm:py-1 sm:px-2">
              <PieChartIcon className="h-4 w-4 sm:mr-2" />
              <span className="text-xs sm:text-sm">Breakdown</span>
            </div>
          </TabsTrigger>
          {/*
          <TabsTrigger value="response-time" className="rounded-md data-[state=active]:bg-background data-[state=active]:text-foreground">
            <div className="flex flex-col items-center sm:flex-row sm:justify-center gap-1 py-2 px-1 sm:py-1 sm:px-2">
              <TrendingUp className="h-4 w-4 sm:mr-2" />
              <span className="text-xs sm:text-sm">Response</span>
            </div>
          </TabsTrigger>
          */}
        </TabsList>
        <TabsContent value="touchpoints" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Monthly Touchpoints by Type</CardTitle>
            </CardHeader>
            <CardContent className="w-full h-[300px] sm:h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={touchpointData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Email" stackId="a" fill="#0088FE" />
                  <Bar dataKey="Phone" stackId="a" fill="#00C49F" />
                  <Bar dataKey="SMS" stackId="a" fill="#FFBB28" />
                  <Bar dataKey="In-Person" stackId="a" fill="#FF8042" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="communication" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Communication Method Distribution</CardTitle>
            </CardHeader>
            <CardContent className="w-full h-[300px] sm:h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={communicationData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius="80%"
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {communicationData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="response-time" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Average Response Time Trend</CardTitle>
            </CardHeader>
            <CardContent className="w-full h-[300px] sm:h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={responseTimeData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="time" stroke="#8884d8" activeDot={{ r: 8 }} strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Dashboard;

