import React from 'react';
import { Textarea } from "components/ui/textarea";
import { Input } from "components/ui/input";
import { Fieldset } from 'components/catalyst/fieldset';
import { SearchAccountSwitch } from "components/layout/SearchAccountSwitch";

export function CreateText({ title, setTitle, content, setContent, selectedAccount, setSelectedAccount }) {
  return (
    <form action="/" method="POST">
      <Fieldset>
          <Textarea
            className="p-4"
            placeholder={`Type Note Here...`}
            rows={8}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
      </Fieldset>
    </form>
  );
}
