import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AppContext } from 'contexts/AppContext';
import { getAccessToken } from 'utils/authUtils'

const AuthGuard = ({ children }) => {
  const { testing } = useContext(AppContext);
  if (testing) return children

  const accessToken = getAccessToken(false);
  if (accessToken && (accessToken!="eyJraWQiOiJLTHpSQmN1XzVySHpmUVV2NXo5Q25CR0NpV1RrZ1g4ZEFrNjBKd3lTZ1djIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxMHZndW5vbXZ4cVEzaDJwOCIsIm5hbWUiOiJOYWRpYSBIb3NzYWluIiwiZW1haWwiOiJuYWRpYUBpbnN0YWxpbHkuYWkiLCJ2ZXIiOjEsImlzcyI6Imh0dHBzOi8vc3JzLm9rdGEuY29tL29hdXRoMi9hdXN2b2pndjl4dnpDU0RpWDJwNyIsImF1ZCI6IjBvYXY2eWFkMDdSa3NlNzVaMnA3IiwiaWF0IjoxNzM3NDcwNjc0LCJleHAiOjE3Mzc0NzQyNzQsImp0aSI6IklELnRDNDR0NHdqU1ViWjNMMGk4SG12X0RWUXNWRjZzaHEyT2w5amFFSmt5b3MiLCJhbXIiOlsibWZhIiwib3RwIiwicHdkIl0sImlkcCI6IjAwbzE2emp3ZWNDRnU3cFVqMnA3Iiwibm9uY2UiOiJISEF2aVJuckQxeEUyTThlUnJpSlF1WUN6UjJRVGtpWGc0NHdmUnRiRGZIWjJ4dVhzelFBUjZHYzRPM2FwQXlsIiwicHJlZmVycmVkX3VzZXJuYW1lIjoibmgxMTAwMzYiLCJhdXRoX3RpbWUiOjE3Mzc0NzA2NzIsImF0X2hhc2giOiI5bUl0azlLcVVNZmkxOVJVVzVzdm1RIn0.GYeTtsKH5lOJzD6BGfHgBbSrEQmfvGuKN-8iFmaIEQoR_iNN9Tv7v5B3WF5HjU2AGJF_blfARBaNvvDKXh27Mz2hieRrPTlMvLaLI9jCzEDXVzZmwbh-9Xo4rJXgcOymKHBT5EswP-XMH4zUMMcPCEBtot73CylZBgs2G2XmHHzc4JIG2hNmlhL7c-mGb_LEeASjeFNO5evxjlwxBDOYeTCqBKz5iGZYg8c-bHajtlWZhLn8qthaOiYr9Vp1Wk2WpVbLuM0zNkVLJruSHck4vBkkI0-bHOvlnhR-rPeCo-cYOoDcQr4fbk20tT2iPIijFWLcLdoxuiNjnyhRVJWUqQ")) {
    return children;
  }

  const { authState, oktaAuth } = useOktaAuth();

  const isAuthenticated = useMemo(() => authState?.isAuthenticated, [authState]);
  const isLoading = useMemo(() => !authState, [authState]);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (!isAuthenticated && !isLoading && !currentUrl.includes('/login/callback')) {
      oktaAuth.signInWithRedirect();
    }
  }, [isAuthenticated, isLoading, oktaAuth]);

  if (isLoading || !isAuthenticated) return null;

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AuthGuard);
