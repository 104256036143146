import { useState, useEffect } from 'react'
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Card, CardContent } from "components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar"
import { Badge } from "components/ui/badge"
import { ArrowUpCircle, ArrowDownCircle, MessageSquare, MoreHorizontal, Send, ChevronLeft, ChevronRight, RefreshCw } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select"
import { Link } from 'react-router-dom'

const topics = [
  "Sales", "Product", "Customer Service", "Technical", "Market Trends", "Supply Chain"
]

const POSTS_PER_PAGE = 5

export default function WaterCoolerPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [newPost, setNewPost] = useState('')
  const [selectedTopic, setSelectedTopic] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [sortByRecency, setSortByRecency] = useState('most')
  const [sortByActivity, setSortByActivity] = useState('none')
  const [sortByVotes, setSortByVotes] = useState('none')
  const [posts, setPosts] = useState([
    {
      id: 1,
      content: "What's your go-to strategy for upselling premium roofing materials to hesitant customers?",
      author: "User 54321",
      time: "2h ago",
      votes: 42,
      comments: 8,
      topics: ["Sales", "Product"],
      timestamp: Date.now() - 2 * 60 * 60 * 1000,
    },
    {
      id: 2,
      content: "Just heard about a new eco-friendly shingle that's supposed to last 50 years. Anyone have experience with it?",
      author: "User 67890",
      time: "5h ago",
      votes: 38,
      comments: 12,
      topics: ["Product", "Technical", "Market Trends", "Supply Chain"],
      timestamp: Date.now() - 5 * 60 * 60 * 1000,
    },
    {
      id: 3,
      content: "Supply chain delays are killing us. How are you managing customer expectations during these times?",
      author: "User 13579",
      time: "1d ago",
      votes: 56,
      comments: 23,
      topics: ["Customer Service", "Supply Chain"],
      timestamp: Date.now() - 24 * 60 * 60 * 1000,
    },
    {
      id: 4,
      content: "Looking for recommendations on the best project management software for roofing companies. Any suggestions?",
      author: "User 24680",
      time: "3h ago",
      votes: 15,
      comments: 7,
      topics: ["Technical"],
      timestamp: Date.now() - 3 * 60 * 60 * 1000,
    },
    {
      id: 5,
      content: "Has anyone implemented a successful referral program? What incentives work best?",
      author: "User 11223",
      time: "6h ago",
      votes: 28,
      comments: 14,
      topics: ["Sales", "Customer Service"],
      timestamp: Date.now() - 6 * 60 * 60 * 1000,
    },
    {
      id: 6,
      content: "Discussing the impact of recent tariffs on imported roofing materials. How are you adjusting your pricing?",
      author: "User 33445",
      time: "1d ago",
      votes: 37,
      comments: 19,
      topics: ["Market Trends", "Supply Chain"],
      timestamp: Date.now() - 24 * 60 * 60 * 1000,
    },
    {
      id: 7,
      content: "New regulations on energy-efficient roofing in our state. Anyone else dealing with this?",
      author: "User 55667",
      time: "4h ago",
      votes: 31,
      comments: 16,
      topics: ["Technical", "Market Trends"],
      timestamp: Date.now() - 4 * 60 * 60 * 1000,
    },
  ])

  const [votedPosts, setVotedPosts] = useState({})
  const [flashVotes, setFlashVotes] = useState({})

  const handleNewPostSubmit = (e) => {
    e.preventDefault();
    if (newPost.trim() === '') return;

    const newPostObject = {
      id: posts.length + 1,
      content: newPost,
      author: "Current User", // Replace with actual user data
      time: "Just now",
      votes: 0,
      comments: 0,
      topics: ["General"], // Default topic or allow user to select
      timestamp: Date.now(),
    };

    setPosts([newPostObject, ...posts]);
    setNewPost(''); // Clear the input field
  };

  const handleResetFilters = () => {
    setSelectedTopic('');
    setSortByRecency('most');
    setSortByActivity('none');
    setSortByVotes('none');
    setCurrentPage(1);
  };

  const filteredPosts = selectedTopic
    ? posts.filter(post => post.topics.includes(selectedTopic))
    : posts

  const sortedPosts = [...filteredPosts].sort((a, b) => {
    if (sortByRecency !== 'none') {
      return sortByRecency === 'most' ? b.timestamp - a.timestamp : a.timestamp - b.timestamp
    }
    if (sortByActivity !== 'none') {
      return sortByActivity === 'most' ? b.comments - a.comments : a.comments - b.comments
    }
    if (sortByVotes !== 'none') {
      return sortByVotes === 'most' ? b.votes - a.votes : a.votes - b.votes
    }
    return 0
  })

  const totalPages = Math.ceil(sortedPosts.length / POSTS_PER_PAGE)
  const paginatedPosts = sortedPosts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  )

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    window.scrollTo(0, 0)
  }

  const handleSortChange = (type, value) => {
    if (type === 'recency') {
      setSortByRecency(value)
      setSortByActivity('none')
      setSortByVotes('none')
    } else if (type === 'activity') {
      setSortByRecency('none')
      setSortByActivity(value)
      setSortByVotes('none')
    } else if (type === 'votes') {
      setSortByRecency('none')
      setSortByActivity('none')
      setSortByVotes(value)
    }
    setCurrentPage(1)
  }

  const handleVote = (postId, direction) => {
    setPosts(posts.map(post => {
      if (post.id === postId) {
        if (votedPosts[postId] === direction) {
          setVotedPosts(prev => ({ ...prev, [postId]: null }))
          return {
            ...post,
            votes: post.votes + (direction === 'up' ? -1 : 1)
          }
        }
        else if (votedPosts[postId]) {
          setVotedPosts(prev => ({ ...prev, [postId]: direction }))
          return {
            ...post,
            votes: post.votes + (direction === 'up' ? 2 : -2)
          }
        }
        else {
          setVotedPosts(prev => ({ ...prev, [postId]: direction }))
          return {
            ...post,
            votes: post.votes + (direction === 'up' ? 1 : -1)
          }
        }
      }
      return post
    }))

    setFlashVotes(prev => ({ ...prev, [postId]: direction }))
    
    setTimeout(() => {
      setFlashVotes(prev => ({ ...prev, [postId]: null }))
    }, 1000)
  }

  return (
    <div className="max-w-2xl mx-auto p-4 mt-8">
      <div className="mb-6">
        <form onSubmit={handleNewPostSubmit}>
          <div className="flex items-center space-x-2">
            <Input
              placeholder="Share an insight or ask a question..."
              value={newPost}
              onChange={(e) => setNewPost(e.target.value)}
              className="flex-grow"
            />
            <Button type="submit">
              <Send className="h-4 w-4 mr-2" />
              Share
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <MoreHorizontal className="h-5 w-5" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Menu</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <Link to="/wc-profile">Profile</Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link to="/wc-saved">Saved Posts</Link>
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  <Link to="/wc-settings">Settings</Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </form>
      </div>

      <div className="mb-4 flex flex-wrap items-center gap-2">
        <Badge
          variant={selectedTopic === '' ? 'default' : 'outline'}
          className="cursor-pointer"
          onClick={() => {
            setSelectedTopic('');
          }}
        >
          All
        </Badge>
        {topics.map(topic => (
          <Badge
            key={topic}
            variant={selectedTopic === topic ? 'default' : 'outline'}
            className="cursor-pointer"
            onClick={() => {
              setSelectedTopic(topic);
            }}
          >
            {topic}
          </Badge>
        ))}
      </div>

      <div className="mb-4 flex flex-wrap items-center gap-2">
        <Select
          value={sortByRecency}
          onValueChange={(value) => {
            setSortByRecency(value);
            setSortByActivity('none');
            setSortByVotes('none');
          }}
        >
          <SelectTrigger className="w-[140px]">
            <SelectValue placeholder="Recency" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="none">Recency</SelectItem>
            <SelectItem value="most">Most Recent</SelectItem>
            <SelectItem value="least">Least Recent</SelectItem>
          </SelectContent>
        </Select>
        <Select
          value={sortByActivity}
          onValueChange={(value) => {
            setSortByActivity(value);
            setSortByRecency('none');
            setSortByVotes('none');
          }}
        >
          <SelectTrigger className="w-[140px]">
            <SelectValue placeholder="Activity" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="none">Activity</SelectItem>
            <SelectItem value="most">Most Active</SelectItem>
            <SelectItem value="least">Least Active</SelectItem>
          </SelectContent>
        </Select>
        <Select
          value={sortByVotes}
          onValueChange={(value) => {
            setSortByVotes(value);
            setSortByRecency('none');
            setSortByActivity('none');
          }}
        >
          <SelectTrigger className="w-[140px]">
            <SelectValue placeholder="Votes" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="none">Votes</SelectItem>
            <SelectItem value="most">Most Votes</SelectItem>
            <SelectItem value="least">Least Votes</SelectItem>
          </SelectContent>
        </Select>
        <Button onClick={handleResetFilters} variant="outline" size="sm">
          <RefreshCw className="h-4 w-4 mr-2" />
          Reset
        </Button>
      </div>

      <div className="space-y-4">
        {paginatedPosts.map((post) => (
          <Card key={post.id}>
            <CardContent className="p-4">
              {post.id === 1 ? (
                <Link to="/wc-example" className="text-sm mb-2 block">
                  {post.content}
                </Link>
              ) : (
                <p className="text-sm mb-2 block">{post.content}</p>
              )}
              <div className="flex flex-wrap gap-2 mb-2">
                {post.topics.slice(0, 2).map(topic => (
                  <Badge key={topic} variant="secondary">{topic}</Badge>
                ))}
                {post.topics.length > 2 && (
                  <Badge variant="secondary">+{post.topics.length - 2} more</Badge>
                )}
              </div>
              <div className="flex items-center justify-between text-sm text-gray-500">
                <div className="flex items-center space-x-2">
                  <Avatar className="h-6 w-6">
                    <AvatarFallback>{post.author[0]}</AvatarFallback>
                  </Avatar>
                  <span>{post.author}</span>
                  <span>·</span>
                  <span>{post.time}</span>
                </div>
                <div className="flex items-center">
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => handleVote(post.id, 'up')}
                    className={`transition-all duration-300 ease-in-out hover:-translate-y-1 hover:shadow-sm active:translate-y-0 ${
                      flashVotes[post.id] === 'up' 
                        ? 'text-green-700/40 hover:text-green-700/50 scale-110 rotate-0' 
                        : 'hover:text-green-700/40 hover:scale-105 -rotate-1'
                    }`}
                  >
                    <ArrowUpCircle className={`h-4 w-4 transition-transform duration-200 ${
                      flashVotes[post.id] === 'up' ? 'scale-110 rotate-12' : ''
                    }`} />
                  </Button>
                  <span className={`transition-all duration-300 ease-out min-w-[2rem] text-center ${
                    flashVotes[post.id] 
                      ? 'scale-110 font-medium translate-y-0' 
                      : 'scale-100 -translate-y-0.5'
                    } ${
                      flashVotes[post.id] === 'up'
                        ? 'text-green-700/40'
                        : flashVotes[post.id] === 'down'
                          ? 'text-red-700/40'
                          : 'text-gray-500'
                    }`}>
                    {post.votes}
                  </span>
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => handleVote(post.id, 'down')}
                    className={`transition-all duration-300 ease-in-out hover:translate-y-1 hover:shadow-sm active:translate-y-0 ${
                      flashVotes[post.id] === 'down' 
                        ? 'text-red-700/40 hover:text-red-700/50 scale-110 rotate-0' 
                        : 'hover:text-red-700/40 hover:scale-105 rotate-1'
                    }`}
                  >
                    <ArrowDownCircle className={`h-4 w-4 transition-transform duration-200 ${
                      flashVotes[post.id] === 'down' ? 'scale-110 -rotate-12' : ''
                    }`} />
                  </Button>
                </div>
                <Button variant="ghost" size="sm">
                  <MessageSquare className="h-4 w-4 mr-1" />
                  {post.comments}
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      {totalPages > 1 && (
        <div className="flex justify-center items-center space-x-2 mt-6">
          <Button
            variant="outline"
            size="sm"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <ChevronLeft className="h-4 w-4" />
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <Button
              key={page}
              variant={currentPage === page ? "default" : "outline"}
              size="sm"
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Button>
          ))}
          <Button
            variant="outline"
            size="sm"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      )}
    </div>
  )
}
