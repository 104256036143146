import React, { useState, useContext } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Sailboat } from 'lucide-react';
import { ChatContext } from 'contexts/ChatContext';
import { useTheme } from 'next-themes';

import MobileChatLayout from './mobileChatLayout';
import DesktopChatLayout from './desktopChatLayout';

const Cosailor = () => {  
  const { isMobile } = useContext(ChatContext);
  const [open, setOpen] = useState(false);
  const { theme } = useTheme(); // Access the theme from next-themes

  const handleToggle = () => {
    setOpen(!open);
  };

  const iconColor = theme === 'dark' ? '#FFFFFF' : '#000000'; // Set color based on theme

  return (
    <>
      {isMobile ? (
        <MobileChatLayout open={open} handleToggle={handleToggle} />
      ) : (
        <>
          <button
            onClick={handleToggle}
            aria-label="Toggle Chat"
            className={`flex flex-col items-center gap-0.5 px-2 py-1 
                        hover:bg-gray-100 rounded-md transition`}
          >
            <Sailboat
              className="w-6 h-6"
              style={{ color: iconColor }}
            />
            <span className="text-xs" style={{ color: iconColor }}>
              Chat
            </span>
          </button>
          <DesktopChatLayout open={open} handleToggle={handleToggle} />
        </>
      )}
    </>
  );
};

export default Cosailor;